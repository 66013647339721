import React from 'react';
import type { QuizData, QuizQuestion, QuizAnswer } from '@superlekcja/shared';

interface QuizProps {
  data: QuizData;
  showAnswers: boolean;
}

export const Quiz: React.FC<QuizProps> = ({
  data,
  showAnswers,
}) => {
  return (
    <div data-testid="quiz-puzzle" className="space-y-6">
      {data.questions.map((question: QuizQuestion, index: number) => (
        <div key={index} className="space-y-3">
          <div className="font-medium">
            {index + 1}. {question.question}
          </div>
          <div className="space-y-2 pl-6">
            {question.answers.map((answer: QuizAnswer, answerIndex: number) => (
              <div
                key={answerIndex}
                className={`flex items-center gap-2 ${
                  showAnswers && answer.isCorrect
                    ? 'text-[#a20276] font-medium'
                    : ''
                }`}
              >
                <span>
                  {String.fromCharCode(97 + answerIndex)})
                </span>
                <span>{answer.answer}</span>
                {showAnswers && answer.isCorrect && (
                  <span className="text-[#a20276]">✓</span>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}; 