import { ReactNode } from 'react';

interface PanelProps {
  children: ReactNode;
}

export function Panel({ children }: PanelProps) {
  return (
    <div className="
      relative
      bg-white
      rounded-xl
      shadow-sm 
      mx-auto
      w-full
      p-8
      md:px-6
      lg:max-w-4xl
      xl:max-w-4xl
      2xl:max-w-4xl
      /* Reset all positioning and margins for print */
      print:static
      print:ml-0
      print:left-auto
      print:transform-none
      print:max-w-none
      print:w-full
      print:px-0
      print:shadow-none 
      print:my-0 
      print:mx-0
    ">
      {children}
    </div>
  );
} 