import { useEffect, useState } from 'react'
import { CookieConsentContent } from './CookieConsentContent'
import { privacyService } from '../services/privacy.service'

const COOKIE_CONSENT_KEY = 'cookie-consent-v1'

export function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!privacyService.hasConsentBeenGiven()) {
      setIsVisible(true)
    }
  }, [])

  const handleAccept = () => {
    privacyService.setCookieConsent('accepted')
    setIsVisible(false)
  }

  const handleReject = () => {
    privacyService.setCookieConsent('rejected')
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <div className="fixed bottom-4 right-4 z-50 w-[calc(100%-2rem)] lg:w-[420px] bg-white rounded-lg shadow-lg border border-gray-200 p-6" data-testid="cookie-consent">
      <CookieConsentContent 
        onAccept={handleAccept}
        onReject={handleReject}
      />
    </div>
  )
} 