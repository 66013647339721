import React from 'react';
import type { RiddleData } from '@superlekcja/shared';

interface RiddleProps {
  data: RiddleData;
  showAnswers: boolean;
  showHints: boolean;
}

export const Riddle: React.FC<RiddleProps> = ({
  data,
  showAnswers,
  showHints,
}) => {
  return (
    <div data-testid="riddles-puzzle" className="space-y-6">
      {data.riddles.map((r, index) => (
        <div key={index} className="border rounded-lg p-4 space-y-3">
          <div className="font-medium">
            {index + 1}. {r.question}
          </div>
          {showHints && r.hint && (
            <div className="text-sm text-muted-foreground italic">
              Podpowiedź: {r.hint}
            </div>
          )}
          {showAnswers && (
            <div className="text-[#a20276] font-medium">
              Odpowiedź: {r.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}; 