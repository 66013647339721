import { Subject, SUBJECTS } from '@superlekcja/shared'
import {
  Sprout,
  Calculator,
  FlaskConical,
  Shield,
  Scale,
  Atom,
  Globe2,
  BookMarked,
  Laptop2,
  Languages,
  Pencil,
  ScrollText,
  Scroll,
  Music2,
  Palette,
  Leaf,
  Wrench,
  Building2,
  Heart,
  PersonStanding,
  Pin,
  type LucideIcon
} from 'lucide-react'

export const subjectIcons: Record<Subject, LucideIcon> = {
  [SUBJECTS.Biologia]: Sprout,
  [SUBJECTS.Chemia]: FlaskConical,
  [SUBJECTS.EdukacjaDlaBezpieczenstwa]: Shield,
  [SUBJECTS.Etyka]: Scale,
  [SUBJECTS.Fizyka]: Atom,
  [SUBJECTS.Geografia]: Globe2,
  [SUBJECTS.Historia]: BookMarked,
  [SUBJECTS.Informatyka]: Laptop2,
  [SUBJECTS.JezykMniejszosci]: Languages,
  [SUBJECTS.JezykObcy]: Languages,
  [SUBJECTS.JezykPolski]: Pencil,
  [SUBJECTS.JezykKaszubski]: ScrollText,
  [SUBJECTS.JezykLacinski]: Scroll,
  [SUBJECTS.Matematyka]: Calculator,
  [SUBJECTS.Muzyka]: Music2,
  [SUBJECTS.Plastyka]: Palette,
  [SUBJECTS.Przyroda]: Leaf,
  [SUBJECTS.Technika]: Wrench,
  [SUBJECTS.WOS]: Building2,
  [SUBJECTS.WychowanieDoZycia]: Heart,
  [SUBJECTS.WF]: PersonStanding,
  [SUBJECTS.Inne]: Pin
} 