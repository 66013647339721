import { MillionairesData } from '@superlekcja/shared'
import { Link } from 'react-router-dom'
import { GamepadIcon } from 'lucide-react'
import { Button } from '../ui/button'

interface MillionairesEditorProps {
  data: MillionairesData;
  showAnswers: boolean;
  lessonId: string;
  itemId: string;
}

export function MillionairesEditor({ data, showAnswers, lessonId, itemId }: MillionairesEditorProps) {
  return (
    <div className="space-y-8">
      <Link 
        to={`/lessons/${lessonId}/worksheet/${itemId}`}
      >
        <Button 
          size="lg"
          className="gap-2 text-base font-medium shadow-md hover:shadow-lg transition-all px-10"
        >
          <GamepadIcon className="h-5 w-5" />
            Graj
        </Button>
      </Link>

      {data.questions.map((question, questionIndex) => (
        <div key={questionIndex} className="bg-white rounded-lg p-6 shadow-sm border">
          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Pytanie {questionIndex + 1}</h3>
            <p className="text-gray-700">{question.question}</p>
          </div>
          
          <div className="space-y-3">
            {question.answers.map((answer, answerIndex) => (
              <div 
                key={answerIndex} 
                className={`p-3 rounded-lg border ${showAnswers && answer.isCorrect ? 'bg-green-50 border-green-200' : 'bg-gray-50 border-gray-200'}`}
              >
                <div className="flex items-center gap-2">
                  <span className="font-medium">{String.fromCharCode(65 + answerIndex)}.</span>
                  <span>{answer.text}</span>
                  {showAnswers && answer.isCorrect && (
                    <span className="ml-auto text-green-600 text-sm font-medium">Poprawna odpowiedź</span>
                  )}
                </div>
              </div>
            ))}
          </div>

          {question.hostComment && (
            <div className="mt-4 print:hidden">
              <p className="text-sm italic text-gray-600">
                <span className="font-medium not-italic">Hubert: </span>
                {question.hostComment}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
} 