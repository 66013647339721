import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from '@/lib/utils';

export function BlogBanner() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if banner was previously dismissed
    const isBannerDismissed = localStorage.getItem('blogBannerDismissed') === 'true';
    setIsVisible(!isBannerDismissed);
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem('blogBannerDismissed', 'true');
  };

  const handleBlogClick = () => {
    handleDismiss();
    // Open blog in new tab
    window.open('https://blog.superlekcja.pl', '_blank');
  };

  if (!isVisible) return null;

  return (
    <div className="bg-[#5d6eb1] border-b border-white/10">
      <div className="mx-auto h-10 flex items-center justify-center relative">
        <button 
          onClick={handleBlogClick}
          className="text-sm font-medium text-white underline-offset-4 hover:underline max-w-[calc(100vw-120px)] truncate px-4"
        >
          Odwiedź nasz blog - praktyczne wykorzystanie AI w nauczaniu
        </button>
        <Button
          variant="ghost"
          size="icon"
          className="absolute right-4 h-8 w-8 text-white/80 hover:text-white hover:bg-white/10"
          onClick={handleDismiss}
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Zamknij</span>
        </Button>
      </div>
    </div>
  );
} 