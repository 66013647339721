import { useState, useEffect } from 'react';

export function useCyclingText(words: string[], intervalMs: number = 10000) {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    
    const interval = setInterval(() => {
      setIsVisible(false); // Start fade out
      
      // After fade out, change word and start fade in
      setTimeout(() => {
        currentIndex = (currentIndex + 1) % words.length;
        setCurrentWord(words[currentIndex]);
        setIsVisible(true);
      }, 1500); // Half of the CSS transition time
      
    }, intervalMs);

    return () => clearInterval(interval);
  }, [words, intervalMs]);

  return { currentWord, isVisible };
} 