import { Button } from "./button"
import { Loader2 } from "lucide-react"

interface GenerateButtonProps {
  onClick?: () => void
  disabled?: boolean
  isGenerating?: boolean
  variant?: "default" | "ghost"
  className?: string
}

export function GenerateButton({
  onClick,
  disabled,
  isGenerating,
  variant = "default",
  className
}: GenerateButtonProps) {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      data-testid="generate-button"
      className={`
        bg-hero text-white hover:bg-hero/90 border-transparent
        disabled:bg-hero disabled:opacity-50 disabled:hover:bg-hero
        ${className}
      `}
    >
      {isGenerating && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {isGenerating ? 'Generowanie...' : 'Generuj'}
    </Button>
  )
} 