import { useNavigate } from 'react-router-dom'
import { PasswordChangeForm } from '../components/auth/PasswordChangeForm'

export function ResetPasswordPage() {
  const navigate = useNavigate()

  const handleSuccess = () => {
    navigate('/', { replace: true })
  }

  return (
    <div className="container mx-auto max-w-md py-12">
      <PasswordChangeForm onSuccess={handleSuccess} title="Reset hasła" subtitle='Ustaw nowe hasło do swojego konta' submitLabel="Zastosuj" />
    </div>
  )
} 
