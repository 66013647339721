import { useState } from 'react'
import { Avatar, AvatarFallback } from '../ui/avatar'
import { Button } from '../ui/button'
import { UserProfileDialog } from './UserProfileDialog'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts/AuthContext'
import { useProfileDialog } from '@/contexts/ProfileDialogProvider'

interface UserAvatarProps {
  email: string
}

export function UserAvatar({ email }: UserAvatarProps) {
  const {openDialog,closeDialog,isOpen} = useProfileDialog()

  const initials = email.charAt(0).toUpperCase()

  

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="relative h-9 w-9 rounded-full hover:bg-primary-foreground/10"
        onClick={openDialog}
        data-testid="user-avatar"
      >
        <Avatar className="h-9 w-9">
          <AvatarFallback className="bg-primary-foreground/10 text-primary-foreground border-2 border-primary-foreground/20">
            {initials}
          </AvatarFallback>
        </Avatar>
      </Button>


    </>
  )
} 