import { useState } from 'react'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { supabase } from '../../lib/supabase'
import { Alert, AlertDescription } from '../ui/alert'
import { validatePassword } from '../../utils/passwordValidation'

interface PasswordChangeFormProps {
  onSuccess: () => void
  title?: string
  subtitle?: string
  submitLabel?: string
}

export function PasswordChangeForm({ 
  onSuccess,
  title = "Zmień hasło",
  subtitle = "Wprowadź nowe hasło do swojego konta",
  submitLabel = "Zmień hasło"
}: PasswordChangeFormProps) {
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)
    setValidationErrors([])

    // Validate password
    const validation = validatePassword(password)
    if (!validation.isValid) {
      setValidationErrors(validation.errors)
      return
    }

    setIsLoading(true)

    try {
      const { error } = await supabase.auth.updateUser({
        password: password
      })

      if (error) throw error

      onSuccess()
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred')
      setIsLoading(false)
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    // Clear validation errors when user starts typing
    if (validationErrors.length > 0) {
      setValidationErrors([])
    }
  }

  return (
    <div className="bg-white/80 backdrop-blur-sm rounded-xl p-8 shadow-sm">
      <div className="space-y-6">
        <div className="space-y-2 text-center">
          <h1 className="text-2xl font-semibold tracking-tight">
            {title}
          </h1>
          <p className="text-muted-foreground">
            {subtitle}
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {validationErrors.length > 0 && (
            <Alert variant="destructive">
              <AlertDescription>
                <ul className="list-disc pl-4">
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </AlertDescription>
            </Alert>
          )}

          <div className="space-y-2">
            <Input
              id="password"
              type="password"
              placeholder="Nowe hasło"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <p className="text-xs text-muted-foreground">
              Hasło musi mieć co najmniej 8 znaków, zawierać cyfrę i znak specjalny
            </p>
          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={isLoading}
          >
            {isLoading ? 'Przetwarzanie...' : submitLabel}
          </Button>
        </form>
      </div>
    </div>
  )
} 