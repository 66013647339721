// src/subjects.ts
var SUBJECTS = {
  Biologia: "biologia",
  Chemia: "chemia",
  EdukacjaDlaBezpieczenstwa: "edukacja-dla-bezpieczenstwa",
  Etyka: "etyka",
  Fizyka: "fizyka",
  Geografia: "geografia",
  Historia: "historia",
  Informatyka: "informatyka",
  JezykMniejszosci: "jezyk-mniejszosci-narodowej-lub-etnicznej",
  JezykObcy: "jezyk-obcy-nowozytny",
  JezykPolski: "jezyk-polski",
  JezykKaszubski: "jezyk-regionalny--jezyk-kaszubski",
  JezykLacinski: "jezyk-lacinski",
  Matematyka: "matematyka",
  Muzyka: "muzyka",
  Plastyka: "plastyka",
  Przyroda: "przyroda",
  Technika: "technika",
  WOS: "wiedza-o-spoleczenstwie",
  WychowanieDoZycia: "wychowanie-do-zycia-w-rodzinie",
  WF: "wychowanie-fizyczne",
  Inne: "inne"
};
var getAllSubjects = () => Object.values(SUBJECTS);

// src/types/scenario.ts
var MAX_USER_PROMPT_LENGTH = 250;

// src/models/availableModels.ts
var AvailableModels = {
  "anthropic": ["claude-3-5-sonnet-latest", "claude-3-5-haiku-latest"],
  "openai": ["gpt-4o", "gpt-4o-mini"],
  "google": ["gemini-2.0-flash"]
};
function isValidModel(modelString) {
  const [provider, model] = modelString.split(":");
  if (!provider || !model) return false;
  if (!(provider in AvailableModels)) return false;
  const availableModels = AvailableModels[provider];
  return availableModels.includes(model);
}
function parseModel(modelString) {
  const [provider, model] = modelString.split(":");
  if (!provider || !model) return null;
  if (!(provider in AvailableModels)) return null;
  return [provider, model];
}
function getModelDisplayName(modelString) {
  const parsed = parseModel(modelString);
  if (!parsed) return null;
  const [provider, model] = parsed;
  return `${provider.charAt(0).toUpperCase() + provider.slice(1)} ${model}`;
}

// src/types/puzzles.ts
import { z } from "zod";
var GetPuzzleParams = z.object({
  id: z.string()
});
var GeneratePuzzleParams = z.object({
  scenarioId: z.string(),
  type: z.enum(["crossword", "wordsearch", "quiz", "riddle", "problem-solving", "memory", "millionaires"]),
  replacePuzzleId: z.string().optional()
});
var WORKSHEET_ITEM_TYPES = [
  "crossword",
  "quiz",
  "wordsearch",
  "riddle",
  "problem-solving",
  "memory",
  "millionaires"
];

// src/types/languages.ts
var languages = [
  { code: "pl", name: "Polski", polishName: "Polski" },
  { code: "pl-csb", name: "Kaszubski", polishName: "Kaszubski" },
  { code: "pl-szl", name: "\u015Al\u0105ski", polishName: "\u015Al\u0105ski" },
  { code: "uk", name: "\u0423\u043A\u0440\u0430\u0457\u043D\u0441\u044C\u043A\u0430", polishName: "Ukrai\u0144ski" },
  { code: "en", name: "English", polishName: "Angielski" },
  { code: "de", name: "Deutsch", polishName: "Niemiecki" },
  { code: "fr", name: "Fran\xE7ais", polishName: "Francuski" },
  { code: "es", name: "Espa\xF1ol", polishName: "Hiszpa\u0144ski" },
  { code: "ru", name: "\u0420\u0443\u0441\u0441\u043A\u0438\u0439", polishName: "Rosyjski" },
  { code: "be", name: "\u0411\u0435\u043B\u0430\u0440\u0443\u0441\u043A\u0430\u044F", polishName: "Bia\u0142oruski" },
  { code: "ko", name: "\uD55C\uAD6D\uC5B4", polishName: "Korea\u0144ski" },
  { code: "ja", name: "\u65E5\u672C\u8A9E", polishName: "Japo\u0144ski" }
];
function getLanguagePolishName(code) {
  return languages.find((language) => language.code === code)?.polishName ?? null;
}
var WORDSEARCH_UNSUPPORTED_LANGUAGES = /* @__PURE__ */ new Set(["ko", "ja"]);
function isWordsearchEnabled(code) {
  return !WORDSEARCH_UNSUPPORTED_LANGUAGES.has(code);
}

// src/types/payment.ts
var PLANS = {
  100: 10,
  200: 20,
  500: 50
};
export {
  AvailableModels,
  GeneratePuzzleParams,
  GetPuzzleParams,
  MAX_USER_PROMPT_LENGTH,
  PLANS,
  SUBJECTS,
  WORKSHEET_ITEM_TYPES,
  getAllSubjects,
  getLanguagePolishName,
  getModelDisplayName,
  isValidModel,
  isWordsearchEnabled,
  languages,
  parseModel
};
