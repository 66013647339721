import { createClient } from '@supabase/supabase-js'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

interface SignUpResult {
  needsEmailVerification: boolean;
  email: string | undefined;
}

// Auth helper functions
export async function signUp(email: string, password: string): Promise<SignUpResult> {
  const invitedBy = sessionStorage.getItem('invitedBy')
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: invitedBy ? {
      data: {
        invitedBy
      }
    } : undefined
  })
  if (error) throw error
  
  // Clear the invitation code after successful signup
  sessionStorage.removeItem('invitedBy')
  
  return {
    needsEmailVerification: true,
    email: data.user?.email
  }
}

export async function signIn(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  })
  if (error) throw error
}

export async function signOut() {
  const { error } = await supabase.auth.signOut()
  if (error) throw error
}
