import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import type { Components } from 'react-markdown'

interface SimpleMarkdownViewProps {
  content: string
  className?: string
}

export function SimpleMarkdownView({ content, className = '' }: SimpleMarkdownViewProps) {
  const components: Components = {
    // Make external links open in new tab
    a: ({ node, children, href, ...props }) => (
      <a 
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    ),
  }

  return (
    <ReactMarkdown 
      className={className}
      remarkPlugins={[remarkGfm]}
      components={components}
    >
      {content}
    </ReactMarkdown>
  )
} 