import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { ProfileDialogProvider } from './contexts/ProfileDialogProvider'
import { Toaster } from './components/ui/toaster'
import { AppRoutes } from './AppRoutes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      retry: 1,
    },
  },
})

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ProfileDialogProvider>
            <AppRoutes />
            <Toaster />
          </ProfileDialogProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
} 