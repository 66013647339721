import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { HomePage } from './pages/HomePage';
import { LessonPage } from './pages/LessonPage';
import { ScenariosPage } from './pages/ScenariosPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { InviteAcceptedPage } from './pages/InviteAcceptedPage';
import { CookieConsent } from './components/CookieConsent';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { TermsPage } from './pages/TermsPage';
import { TestPage } from './pages/TestPage';
import { WorksheetItemPage } from './pages/WorksheetItemPage';

export function AppRoutes() {
  return (
    <>
      <Routes>
        {/* Supabase landing pages */}
        <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
        <Route path="/auth/invite-accepted" element={<InviteAcceptedPage />} />
        <Route path="/auth/email-confirmed" element={<Navigate to="/" replace />} />
        
        {/* Legacy route - can be removed later */}
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/lessons" element={<Navigate to="/" replace />} />
          <Route path="/lessons/:lessonId" element={<LessonPage />} />
          <Route path="/lessons/:lessonId/worksheet/:itemId" element={<WorksheetItemPage />} />
          <Route path="/scenarios" element={<ScenariosPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      <CookieConsent />
    </>
  );
} 