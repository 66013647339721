import React from 'react'
import { Link } from 'react-router-dom'

export function NotFoundPage() {
  return (
    <div className="min-h-[80vh] flex flex-col items-center justify-center px-6">
      <div className="text-9xl font-bold text-gray-200">404</div>
      <h1 className="mt-4 text-3xl font-semibold text-gray-900">
        Strona nie została znaleziona
      </h1>
      <p className="mt-2 text-lg text-gray-600">
        Przepraszamy, nie mogliśmy znaleźć strony, której szukasz.
      </p>
      <Link
        to="/"
        className="mt-8 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
      >
        Wróć do strony głównej
      </Link>
    </div>
  )
} 