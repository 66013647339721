import { AvailableModels } from '@superlekcja/shared';

const LOCAL_STORAGE_KEY = 'selectedModel';

export class ModelStorage {
  private static instance: ModelStorage;

  private constructor() {}

  static getInstance(): ModelStorage {
    if (!ModelStorage.instance) {
      ModelStorage.instance = new ModelStorage();
    }
    return ModelStorage.instance;
  }

  /**
   * Get the currently selected model
   * @returns The model ID in format "provider:model" or undefined if not set
   */
  getSelectedModel(): string | undefined {
    const stored = localStorage.getItem(LOCAL_STORAGE_KEY) ?? undefined;
    // if (!stored) {
    //   // Get first available model as default
    //   const [provider, models] = Object.entries(AvailableModels)[0];
    //   return `${provider}:${models[0]}`;
    // }
    return stored;
  }

  /**
   * Set the selected model
   * @param modelId The model ID in format "provider:model"
   */
  setSelectedModel(modelId: string): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, modelId);
  }
} 