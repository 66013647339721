import { MarkdownView } from './MarkdownView'
import { Panel } from './Panel'
import { ArrowLeft } from 'lucide-react'
import { AIWarningFooter } from './AIWarningFooter'
import type { AlterationDTO } from '@superlekcja/shared'
import { GenerateButton } from './ui/generate-button'

interface KnowledgeContentViewProps {
  content: string
  isGenerating?: boolean
  onHeaderAction?: (modification: AlterationDTO | null) => void
  activeModifications?: AlterationDTO[]
  onGenerate?: () => void
}

export const KnowledgeView = (({
  content,
  isGenerating = false,
  onHeaderAction,
  activeModifications = [],
  onGenerate
} : KnowledgeContentViewProps) => {
  return (
    <Panel>
      {!content && !isGenerating ? (
        <div className="flex flex-col items-center justify-center py-16 text-center space-y-4">
          <div className="text-muted-foreground">
            <p className="flex items-center gap-2 mb-8">
              <span>Wygeneruj kompendium wiedzy dla scenariusza.</span>
            </p>
            <GenerateButton
              onClick={onGenerate}
              disabled={isGenerating}
              isGenerating={isGenerating}
              className="w-64"
            />
          </div>
        </div>
      ) : (
        <>
          <MarkdownView
            content={content}
            onHeaderAction={onHeaderAction}
            activeModifications={activeModifications}
          />
          {content && !isGenerating && <AIWarningFooter />}
        </>
      )}
    </Panel>
  )
}) 