import { MarkdownView } from './MarkdownView'
import { AlterationDTO } from '@superlekcja/shared'
import { Panel } from './Panel'
import { AIWarningFooter } from './AIWarningFooter'

interface ScenarioViewProps {
  content: string
  onHeaderAction?: (modification: AlterationDTO | null) => void
  activeModifications?: AlterationDTO[]
  isGenerating?: boolean
}

export const ScenarioView = ({
  content,
  onHeaderAction,
  activeModifications = [],
  isGenerating = false
}: ScenarioViewProps) => {
  return (
    <Panel>
      <MarkdownView
        content={content}
        onHeaderAction={onHeaderAction}
        activeModifications={activeModifications}
      />
      {content && !isGenerating && <AIWarningFooter />}
    </Panel>
  )
}
