import React, { createContext, useContext, useState, useEffect } from 'react'
import { User } from '@supabase/supabase-js'
import { supabase, signIn, signOut, signUp } from '../lib/supabase'
import { profileService } from '../services/profile.service'
import { ProfileDTO } from '@superlekcja/shared'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import * as Sentry from "@sentry/react";


interface AuthContextType {
  user: User | null;
  profile: ProfileDTO | null;
  isAuthenticated: boolean;
  isTester: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshProfile: () => Promise<void>;
  isProfileLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const queryClient = useQueryClient()

  // Profile query
  const { 
    data: profile, 
    isLoading: isProfileLoading,
    refetch: refetchProfile
  } = useQuery({
    queryKey: ['profile', user?.id],
    queryFn: async () => {
      if (!user) {
        return null;
      }
      // Wait a bit to ensure the session is properly set up
      //await new Promise(resolve => setTimeout(resolve, 500));
      return profileService.fetchProfile();
    },
    // Only run query if we have a user
    enabled: !!user,
    // Retry failed requests 3 times
    retry: 3,
    // Refetch on window focus
    refetchOnWindowFocus: true,
    // Refetch every 5 minutes
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    let mounted = true;

    const initializeAuth = async () => {
      try {
        // Get initial session
        const { data: { session } } = await supabase.auth.getSession();
        if (mounted) {
          setUser(session?.user ?? null);
          Sentry.setUser(session?.user ? { email: "john.doe@example.com" } : null);
        }
      } catch (error) {
        console.error('Error getting initial session:', error);
        if (mounted) {
          setUser(null);
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    // Initialize auth state
    initializeAuth();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (!mounted) return;
      console.log('Auth state changed:', event, session);
      setUser(session?.user ?? null);
    });

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, []);

  const login = async (email: string, password: string) => {
    await signIn(email, password)
  }

  const signup = async (email: string, password: string) => {
    await signUp(email, password)
  }

  const logout = async () => {
    try {
      setIsLoading(true)
      // Invalidate and remove profile from cache
      await queryClient.invalidateQueries({ queryKey: ['profile'] })
      queryClient.removeQueries({ queryKey: ['profile'] })
      await signOut()
    } catch (error) {
      console.error('Error during logout:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const refreshProfile = async () => {
    if (user) {
      await refetchProfile({
        cancelRefetch: false,
        throwOnError: false
      });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    )
  }

  return (
    <AuthContext.Provider value={{
      user,
      profile: profile ?? null,
      isAuthenticated: !!user,
      login,
      signup,
      logout,
      refreshProfile,
      isProfileLoading,
      isTester: profile?.roles?.includes('tester') ?? false
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
} 