const COOKIE_CONSENT_KEY = 'cookie-consent-v1'

export type CookieConsentStatus = 'accepted' | 'rejected' | null

export const privacyService = {
  getCookieConsent(): CookieConsentStatus {
    const consent = localStorage.getItem(COOKIE_CONSENT_KEY)
    if (consent === 'accepted' || consent === 'rejected') {
      return consent
    }
    return null
  },

  setCookieConsent(status: 'accepted' | 'rejected'): void {
    localStorage.setItem(COOKIE_CONSENT_KEY, status)
  },

  hasConsentBeenGiven(): boolean {
    return this.getCookieConsent() !== null
  },

  hasCookiesAccepted(): boolean {
    return this.getCookieConsent() === 'accepted'
  }
} 