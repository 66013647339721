import { cn } from "@/lib/utils"

interface BetaBadgeProps {
  className?: string
}

export function BetaBadge({ className }: BetaBadgeProps) {

  return (
    <div 
      id="beta-badge"
      className={cn(
        "print:hidden",
        "z-50",
        "px-2 py-0.5 text-xs font-medium rounded-full",
        "border border-grey-200/20 backdrop-blur-sm",
        "shadow-sm",
        className
      )}
    >
      beta
    </div>
  )
}