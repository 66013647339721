import { CrosswordData, CrosswordWord } from '@superlekcja/shared';
import React, { useState, useEffect } from 'react';

const CELL_SIZE = 40;
const GRID_PADDING = 20;

interface CellInfo {
  hasLetter: boolean;
  letter?: string;
  number?: number;
}

interface CrosswordPuzzleProps {
  puzzle: CrosswordData;
  showPreview?: boolean;
}

export const CrosswordPuzzle: React.FC<CrosswordPuzzleProps> = ({ 
  puzzle,
  showPreview = false 
}) => {
  const [selectedClue, setSelectedClue] = useState<string | null>(null);
  const [grid, setGrid] = useState<CellInfo[][]>([]);
  const [clueNumbers, setClueNumbers] = useState<Map<string, number>>(new Map());

  // Calculate dimensions
  const width = (puzzle.gridSize.width * CELL_SIZE) + (2 * GRID_PADDING);
  const height = (puzzle.gridSize.height * CELL_SIZE) + (2 * GRID_PADDING);

  // Process puzzle data into grid
  useEffect(() => {
    const crossword = puzzle
    // Initialize empty grid
    const newGrid: CellInfo[][] = Array(crossword.gridSize.height)
      .fill(null)
      .map(() =>
        Array(crossword.gridSize.width)
          .fill(null)
          .map(() => ({ hasLetter: false }))
      );

    // Add words to grid and assign numbers
    let currentNumber = 1;
    const newClueNumbers = new Map<string, number>();
    const positionNumbers = new Map<string, number>();

    // First pass: assign numbers to positions
    crossword.words.forEach((wordData) => {
      const { position } = wordData;
      const posKey = `${position.x},${position.y}`;
      
      if (!positionNumbers.has(posKey)) {
        positionNumbers.set(posKey, currentNumber++);
      }
    });

    // Second pass: place words and use position numbers
    crossword.words.forEach((wordData) => {
      const { word, position, direction } = wordData;
      const key = `${position.x},${position.y},${direction}`;
      const posKey = `${position.x},${position.y}`;
      
      // Use the number assigned to this position
      newClueNumbers.set(key, positionNumbers.get(posKey)!);

      // Place letters
      [...word].forEach((letter, index) => {
        const x = direction === 'across' ? position.x + index : position.x;
        const y = direction === 'down' ? position.y + index : position.y;
        
        newGrid[y][x] = {
          hasLetter: true,
          letter,
          number: index === 0 ? positionNumbers.get(posKey) : undefined
        };
      });
    });

    setGrid(newGrid);
    setClueNumbers(newClueNumbers);
  }, [puzzle]);

  const renderGrid = () => {
    const elements: JSX.Element[] = [];

    // Add background first
    elements.push(
      <rect
        key="background"
        x="0"
        y="0"
        width={(puzzle.gridSize.width * CELL_SIZE) + (2 * GRID_PADDING)}
        height={(puzzle.gridSize.height * CELL_SIZE) + (2 * GRID_PADDING)}
        fill="#f3f4f6"
      />
    );

    grid.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (cell.hasLetter) {
          // Add cell rectangle with white background
          elements.push(
            <rect
              key={`cell-${x}-${y}`}
              x={(x * CELL_SIZE) + GRID_PADDING}
              y={(y * CELL_SIZE) + GRID_PADDING}
              width={CELL_SIZE}
              height={CELL_SIZE}
              fill="white"
              stroke="black"
              strokeWidth="1"
            />
          );

          // Add number if present
          if (cell.number) {
            elements.push(
              <text
                key={`number-${x}-${y}`}
                x={(x * CELL_SIZE) + GRID_PADDING + 5}
                y={(y * CELL_SIZE) + GRID_PADDING + 12}
                fontSize="10"
                textAnchor="start"
              >
                {cell.number}
              </text>
            );
          }

          // Add letter if preview is enabled
          if (showPreview && cell.letter) {
            elements.push(
              <text
                key={`letter-${x}-${y}`}
                x={(x * CELL_SIZE) + GRID_PADDING + (CELL_SIZE / 2)}
                y={(y * CELL_SIZE) + GRID_PADDING + (CELL_SIZE / 2) + 5}
                textAnchor="middle"
                fontSize="20"
                fill="#666"
              >
                {cell.letter}
              </text>
            );
          }
        }
      });
    });

    return elements;
  };

  const renderLetters = () => {
    const elements: JSX.Element[] = [];

    grid.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (cell.hasLetter) {
          elements.push(
            <text
              key={`letter-${x}-${y}`}
              x={(x * CELL_SIZE) + GRID_PADDING + (CELL_SIZE / 2)}
              y={(y * CELL_SIZE) + GRID_PADDING + (CELL_SIZE / 2) + 5}
              textAnchor="middle"
              fontSize="20"
              fill="#666"
            >
              {cell.letter}
            </text>
          );
        }
      });
    });

    return elements;
  };

  if (!puzzle) {
    return null;
  }

  // Filter and sort clues
  const acrossClues = puzzle.words
    .filter((w: CrosswordWord) => w.direction === 'across')
    .map((word: CrosswordWord) => ({
      number: clueNumbers.get(`${word.position.x},${word.position.y},${word.direction}`),
      clue: word.clue
    }))
    .sort((a, b) => (a.number || 0) - (b.number || 0));

  const downClues = puzzle.words
    .filter((w: CrosswordWord) => w.direction === 'down')
    .map((word: CrosswordWord) => ({
      number: clueNumbers.get(`${word.position.x},${word.position.y},${word.direction}`),
      clue: word.clue
    }))
    .sort((a, b) => (a.number || 0) - (b.number || 0));

  return (
    <div data-testid="crossword-puzzle" className="flex flex-col gap-8">
            <svg
              viewBox={`0 0 ${width} ${height}`}
              className="w-full h-full max-h-[70vh] print:max-h-[15cm]"
            >
              {renderGrid()}
              {showPreview && renderLetters()}
            </svg>
          


      {/* Clues */}
      <div className="space-y-6">
        {/* Across Clues */}
        <div>
          <h3 className="font-medium mb-2">Poziomo:</h3>
          <div className="space-y-1">
            {acrossClues.filter(({number})=>number).map(({ number, clue }) => (
              <li
                key={`across-${number}`}
                className={`p-2 rounded ${
                  selectedClue === clue ? 'bg-blue-100' : 'hover:bg-blue-50'
                } transition-colors cursor-pointer`}
                onClick={() => setSelectedClue(clue)}
              >
                <span className="font-medium">{number}.</span> {clue}
              </li>
            ))}
          </div>
        </div>

        {/* Down Clues */}
        <div>
          <h3 className="font-medium mb-2">Pionowo:</h3>
          <div className="space-y-1">
            {downClues.filter(({number})=>number).map(({ number, clue }) => (
              <li
                key={`down-${number}`}
                className={`p-2 rounded ${
                  selectedClue === clue ? 'bg-blue-100' : 'hover:bg-blue-50'
                } transition-colors cursor-pointer`}
                onClick={() => setSelectedClue(clue)}
              >
                <span className="font-medium">{number}.</span> {clue}
              </li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}; 