export const businessInfo = {
  name: "Krzysztof Glowacki",
  businessName: "Krzysztof Glowacki Mobicouncil",
  address: {
    street: "Siostrzana",
    number: "2/6",
    city: "Wrocław",
    fullAddress: "Siostrzana 2/6, 53-029 Wrocław"
  },
  contact: {
    email: "kontakt@superlekcja.pl",
    phone: "730684702"
  },
  legal: {
    nip: "6151824742",
    regon: "022154571"
  },
  website: {
    domain: "superlekcja.pl",
    fullUrl: "https://superlekcja.pl"
  }
} as const; 