import React, { useState } from 'react';
import type { WordSearchData } from '@superlekcja/shared';

interface WordSearchProps {
  data: WordSearchData;
  showSolution: boolean;
}

export const WordSearch: React.FC<WordSearchProps> = ({
  data,
  showSolution,
}) => {
  const [selectedCells, setSelectedCells] = useState<Set<string>>(new Set());
  const [startCell, setStartCell] = useState<[number, number] | null>(null);

  // Calculate all cells that contain words
  const getSolutionCells = (): Set<string> => {
    const cells = new Set<string>();
    const { grid, words } = data;
    const height = grid.length;
    const width = grid[0].length;

    // For each cell in the grid
    for (let startY = 0; startY < height; startY++) {
      for (let startX = 0; startX < width; startX++) {
        // Check all possible directions
        const directions = [
          [1, 0],   // right
          [0, 1],   // down
          [1, 1],   // diagonal down-right
          [-1, 1],  // diagonal down-left
        ] as const;

        // For each word
        for (const { word } of words) {
          const upperWord = word.toUpperCase();
          
          // Try each direction
          for (const [dx, dy] of directions) {
            let matches = true;
            const matchingCells: string[] = [];

            // Check if the word fits in this direction starting from this cell
            for (let i = 0; i < word.length; i++) {
              const x = startX + dx * i;
              const y = startY + dy * i;

              if (x < 0 || x >= width || y < 0 || y >= height) {
                matches = false;
                break;
              }

              if (grid[y][x] !== upperWord[i]) {
                matches = false;
                break;
              }

              matchingCells.push(`${y}-${x}`);
            }

            // If we found a match, add all cells to the solution
            if (matches) {
              matchingCells.forEach(cell => cells.add(cell));
            }
          }
        }
      }
    }

    return cells;
  };

  const handleCellClick = (row: number, col: number) => {
    if (showSolution) return; // Disable selection when showing solution
    
    if (!startCell) {
      setStartCell([row, col]);
      setSelectedCells(new Set([`${row}-${col}`]));
    } else {
      const [startRow, startCol] = startCell;
      
      // Calculate direction
      const dx = Math.sign(col - startCol);
      const dy = Math.sign(row - startRow);
      
      // Only allow straight lines (horizontal, vertical, or diagonal)
      if (Math.abs(col - startCol) === Math.abs(row - startRow) || // diagonal
          row === startRow || // horizontal
          col === startCol) { // vertical
        
        const newSelected = new Set<string>();
        const length = Math.max(
          Math.abs(col - startCol),
          Math.abs(row - startRow)
        ) + 1;

        for (let i = 0; i < length; i++) {
          const r = startRow + dy * i;
          const c = startCol + dx * i;
          newSelected.add(`${r}-${c}`);
        }

        setSelectedCells(newSelected);
      }
      
      setStartCell(null);
    }
  };

  const solutionCells = showSolution ? getSolutionCells() : new Set<string>();

  return (
    <div data-testid="wordsearch-puzzle" className="space-y-6">
      <div 
        className="grid gap-px bg-muted w-fit mx-auto border border-muted"
        style={{
          gridTemplateColumns: `repeat(${data.size.width}, 2rem)`
        }}
      >
        {data.grid.map((row: string[], rowIndex: number) => (
          row.map((cell: string, colIndex: number) => (
            <div
              key={`${rowIndex}-${colIndex}`}
              className={`
                w-8 h-8 flex items-center justify-center font-medium bg-background cursor-pointer
                ${showSolution && solutionCells.has(`${rowIndex}-${colIndex}`) ? 'bg-[#a20276]/10 text-[#a20276] border-2 border-[#a20276]' : ''}
                ${!showSolution && selectedCells.has(`${rowIndex}-${colIndex}`) ? 'bg-[#a20276]/20' : ''}
                ${!showSolution ? 'hover:bg-muted-foreground/5' : ''}
              `}
              onClick={() => handleCellClick(rowIndex, colIndex)}
            >
              {cell}
            </div>
          ))
        ))}
      </div>

      <div className="grid grid-cols-2 gap-4">
        {data.words.map((word: { word: string; description: string }, index: number) => (
          <div key={index} className="space-y-1">
            <div className="font-medium">{word.word}</div>
            <div className="text-sm text-muted-foreground">
              {word.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}; 