import { useState, type ChangeEvent, type FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Alert, AlertDescription } from '../ui/alert'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '../ui/dialog'
import { supabase } from '../../lib/supabase'
import { validatePassword } from '../../utils/passwordValidation'

interface AuthFormProps {
  onSuccess?: () => void
}

export function AuthForm({ onSuccess }: AuthFormProps) {
  const navigate = useNavigate()
  const { login, signup } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [verificationEmail, setVerificationEmail] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const [mode, setMode] = useState<'login' | 'reset' | 'register'>('login')

  const registrationEnabled = import.meta.env.VITE_ENABLE_REGISTRATION === 'true'

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(null)
    setMessage(null)
    setValidationErrors([])

    // Validate password for register mode
    if (mode === 'register') {
      const validation = validatePassword(password)
      if (!validation.isValid) {
        setValidationErrors(validation.errors)
        return
      }
    }

    setIsLoading(true)

    try {
      switch (mode) {
        case 'login' : {
          await login(email, password)
          onSuccess?.()
          break
        }
        case 'reset' : {
          const { error } = await supabase.auth.resetPasswordForEmail(email, {
            //redirect is configured in supabase 
            //redirectTo: `${window.location.origin}/reset-password`
          })
          if (error) throw error
          setMessage('Sprawdź swoją skrzynkę email aby zresetować hasło.')
          setIsLoading(false)
          setTimeout(() => {
            onSuccess?.()
            navigate('/')
          }, 2000)
          break
        }
        case 'register' : {
          if (!registrationEnabled) {
            throw new Error('Rejestracja jest obecnie wyłączona')
          }
          await signup(email, password)
          setVerificationEmail(email)
          break;
        }
      }
    } catch (err) {
      setError(mode === 'reset' 
        ? 'Nie udało się wysłać linku do resetowania hasła.' 
        : err instanceof Error ? err.message : 'An error occurred'
      )
      setIsLoading(false)
    } finally {
      if (mode !== 'reset') {
        setIsLoading(false)
      }
    }
  }

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    // Clear validation errors when user starts typing
    if (validationErrors.length > 0) {
      setValidationErrors([])
    }
  }

  if (verificationEmail) {
    return (
      <div className="px-6 py-8 space-y-4 text-center">
        <h2 className="text-2xl font-semibold">Sprawdź swoją skrzynkę email</h2>
        <p className="text-muted-foreground">
          Wysłaliśmy link weryfikacyjny na adres {verificationEmail}. 
          Kliknij w link aby aktywować konto.
        </p>
        <p className="text-sm text-muted-foreground">
          Po weryfikacji możesz się zalogować.
        </p>
      </div>
    )
  }

  function getDialogContent(mode: 'login' | 'reset' | 'register') {
    if (mode === 'register' && !registrationEnabled) {
      return (
        <div className="space-y-4">
          <DialogHeader>
            <DialogDescription>
              Obecnie jesteśmy w fazie testów. 
              Aby uzyskać dostęp, wyślij email na adres{' '}
              <a href="mailto:kontakt@superlekcja.pl?subject=Chcę%20wziąć%20udział%20w%20testach" className="text-primary hover:underline">
                kontakt@superlekcja.pl
              </a>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setMode('login')} className="w-full">
              Powrót do logowania
            </Button>
          </DialogFooter>
        </div>
      )
    }

    switch (mode) {
      case 'login':
        return { 
          title: 'Zaloguj się',
          description: 'Wprowadź swoje dane aby się zalogować',
          buttonText: 'Zaloguj się',
        }
      case 'reset':
        return { 
          title: 'Zresetuj hasło', 
          description: 'Wprowadź swój email aby otrzymać link do resetowania hasła',
          buttonText: 'Wyślij link',
        } 
      case 'register':
        return { 
          title: 'Zarejestruj się',
          description: 'Utwórz nowe konto aby korzystać z aplikacji',
          buttonText: 'Zarejestruj się',
        }
    }
  }

  const dialogContent = getDialogContent(mode)
  if ('title' in dialogContent) {
    const {title, description, buttonText} = dialogContent
    return (
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="px-6 pt-6">
          <DialogHeader>
            <DialogTitle data-testid="auth-modal-title">
              {title}
            </DialogTitle>
            <DialogDescription>
              {description}
            </DialogDescription>
          </DialogHeader>
        </div>

        <div className="px-6 space-y-4">
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {message && (
            <Alert>
              <AlertDescription>{message}</AlertDescription>
            </Alert>
          )}

          {validationErrors.length > 0 && (
            <Alert variant="destructive">
              <AlertDescription>
                <ul className="list-disc pl-4">
                  {validationErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </AlertDescription>
            </Alert>
          )}

          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              data-testid="auth-email-input"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>

          {mode !== 'reset' && (
            <div className="space-y-2">
              <Label htmlFor="password">Hasło</Label>
              <Input
                id="password"
                type="password"
                data-testid="auth-password-input"
                value={password}
                onChange={handlePasswordChange}
                required
              />
              {mode === 'register' && (
                <p className="text-xs text-muted-foreground mt-1">
                  Hasło musi mieć co najmniej 8 znaków, zawierać cyfrę i znak specjalny
                </p>
              )}
            </div>
          )}
        </div>

        <DialogFooter className="px-6 pb-6">
          <div className="flex flex-col gap-2 w-full">
            <Button 
              type="submit" 
              className="w-full"
              disabled={isLoading}
              data-testid="auth-submit-button"
            >
              {isLoading ? 'Przetwarzanie...' : buttonText}
            </Button>

            {mode === 'login' && (
              <Button
                type="button"
                variant="link"
                className="w-full"
                onClick={() => {
                  setMode('reset')
                  setError(null)
                  setMessage(null)
                }}
                disabled={isLoading}
              >
                Zapomniałeś hasła?
              </Button>
            )}

            {mode === 'reset' && (
              <Button
                type="button"
                variant="link"
                className="w-full"
                onClick={() => {
                  setMode('login')
                  setError(null)
                  setMessage(null)
                }}
                disabled={isLoading}
              >
                Wróć do logowania
              </Button>
            )}

            {mode !== 'reset' && (
              <Button
                type="button"
                variant="link"
                className="w-full"
                onClick={() => setMode(mode === 'login' ? 'register' : 'login')}
                disabled={isLoading}
              >
                {mode === 'login' 
                  ? 'Nie masz konta? Zarejestruj się'
                  : 'Masz już konto? Zaloguj się'
                }
              </Button>
            )}
          </div>
        </DialogFooter>
      </form>
    )
  } else {
    return dialogContent
  }
} 
