import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { getUserLessons } from '../services/lesson.service'
import { LessonSummaryDTO } from '@superlekcja/shared'
import { getSubjectIcon } from '@/utils/subjectUtils'
import { Button } from '../components/ui/button'
import React from 'react'

export function ScenariosPage() {
  const [lessons, setLessons] = useState<LessonSummaryDTO[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!isFirstRender.current) {
      return
    }
    isFirstRender.current = false

    const loadLessons = async () => {
      try {
        const lessons = await getUserLessons()
        setLessons(lessons)
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Failed to load lessons')
      } finally {
        setIsLoading(false)
      }
    }

    loadLessons()
  }, [])

  if (error) {
    return (
      <div className="max-w-4xl mx-auto px-6 py-12">
        <div className="bg-red-50 border border-red-200 rounded-xl p-6">
          <h2 className="text-lg font-semibold text-red-800">
            Wystąpił błąd
          </h2>
          <p className="mt-2 text-red-700">{error}</p>
          <Link
            to="/"
            className="mt-4 inline-block text-red-600 hover:text-red-700"
          >
            ← Wróć do strony głównej
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-full relative print:static">
      <div className="absolute inset-0 overflow-y-auto print:static print:overflow-visible">
        <div className="max-w-4xl mx-auto px-6 py-12">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-semibold text-primary">Moje lekcje</h1>
            <Button asChild className="print:hidden">
              <Link to="/">
                + Nowa lekcja
              </Link>
            </Button>
          </div>

          {isLoading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
              <p className="mt-4 text-gray-600">Ładowanie lekcji...</p>
            </div>
          ) : lessons.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-600">Nie masz jeszcze żadnych lekcji.</p>
              <Link
                to="/"
                className="mt-4 inline-block text-blue-600 hover:text-blue-700"
              >
                Stwórz swoją pierwszą lekcję
              </Link>
            </div>
          ) : (
            <div className="grid gap-4" data-testid="lessons-list">
              {lessons.map((lesson) => (
                <Link
                  key={lesson.id}
                  to={`/lessons/${lesson.slug}`}
                  data-testid={`lesson-item-${lesson.id}`}
                  className="block bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 print:shadow-none print:hover:shadow-none"
                >
                  <div className="flex items-start justify-between">
                    <div className="flex gap-3">
                      <div className="text-2xl text-primary">
                        {React.createElement(getSubjectIcon(lesson.subject), { size: 24 })}
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">
                          {lesson.topic || 'Nowa lekcja'}
                        </p>
                        <p className="text-sm text-gray-500 mt-1">
                          {new Date(lesson.updatedAt).toLocaleString('pl-PL', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      {lesson.public && (
                        <span className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded">
                          Publiczna
                        </span>
                      )}
                      <span className="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded">
                        {lesson.language}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
} 