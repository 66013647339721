import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'
import { LanguageCode, languages } from '@superlekcja/shared'



interface LanguageSelectorProps {
  value: LanguageCode
  onValueChange: (value: LanguageCode) => void
  className?: string
  disabled?: boolean
}

export function LanguageSelector({ value, onValueChange, className, disabled = false }: LanguageSelectorProps) {
  return (
    <div className="min-w-[100px]">
      <div className="text-xs text-primary-foreground/80 mb-1.5">Język</div>
      <Select value={value} onValueChange={onValueChange} disabled={disabled}>
        <SelectTrigger 
          className="h-10 w-full bg-transparent border-primary-foreground/20 text-primary-foreground hover:bg-primary-foreground/10"
        >
          <SelectValue placeholder="Wybierz język" />
        </SelectTrigger>
        <SelectContent>
          {languages.map((language) => (
            <SelectItem key={language.code} value={language.code}>
              {language.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
} 