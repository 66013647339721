import { Check, X, ArrowDown } from 'lucide-react'

interface GenerationStatusProps {
  label: string
  onAbort: () => void
  progress: string[]
  autoScroll?: boolean
  onAutoScrollChange?: (enabled: boolean) => void
}

export function GenerationStatus({onAbort, label, progress, autoScroll = false, onAutoScrollChange }: GenerationStatusProps) {
  return (
    <div className="fixed bottom-6 left-1/2 -translate-x-1/2 bg-primary text-primary-foreground rounded-lg shadow-lg py-3 px-4" data-testid="generation-status">
      <div className="flex flex-col gap-3">
        <div>
          <div className="flex items-center gap-2">
            <h3 className="font-medium">{label}</h3>
          </div>
          <div className="flex flex-col gap-1.5 mt-2">
            {progress.map((step, index) => (
              <div key={step} className="flex items-center gap-2 text-sm">
                {index === progress.length - 1 ? (
                  <div className="w-4 h-4 border-2 border-current border-t-transparent rounded-full animate-spin" />
                ) : (
                  <Check className="w-4 h-4" />
                )}
                <span className="opacity-90">{step}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center gap-2 border-t border-primary-foreground/10 pt-2">
          <button
            onClick={() => onAutoScrollChange?.(!autoScroll)}
            className={`flex items-center gap-1.5 px-2 py-1 rounded transition-colors text-sm ${
              autoScroll 
                ? 'text-primary-foreground bg-primary-foreground/10' 
                : 'text-primary-foreground/80 hover:bg-primary-foreground/10 hover:text-primary-foreground'
            }`}
            title="Przewiń do końca"
            data-testid="auto-scroll-toggle"
          >
            <ArrowDown size={16} />
            <span>Przewijaj</span>
          </button>
          <button
            onClick={onAbort}
            className="flex items-center gap-1.5 px-2 py-1 hover:bg-primary-foreground/10 rounded text-primary-foreground/80 hover:text-primary-foreground transition-colors text-sm ml-auto"
            title="Przerwij generowanie"
            data-testid="abort-generation"
          >
            <X size={16} />
            <span>Przerwij</span>
          </button>
        </div>
      </div>
    </div>
  )
} 