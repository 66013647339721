import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from './ui/dialog'
import { CookieConsentContent } from './CookieConsentContent'
import { privacyService } from '../services/privacy.service'
import { useState } from 'react'

interface CookiePreferencesDialogProps {
  isOpen: boolean
  onClose: () => void
}

export function CookiePreferencesDialog({ isOpen, onClose }: CookiePreferencesDialogProps) {

  const handleAccept = () => {
    privacyService.setCookieConsent('accepted')
    onClose()
  }

  const handleReject = () => {
    privacyService.setCookieConsent('rejected')
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[calc(100%-2rem)] lg:max-w-[425px]">
        <DialogTitle>Polityka prywatności i pliki cookie</DialogTitle>
        <CookieConsentContent 
            showTitle={false}
            onAccept={handleAccept}
            onReject={handleReject}
        />
      </DialogContent>
    </Dialog>
  )
} 