import { Button } from './ui/button'
import { Undo, Redo, RotateCcw, ImagePlus } from 'lucide-react'
import { ScenarioGenerationSettingsForm } from './ScenarioGenerationSettingsForm'
import { GenerateButton } from './ui/generate-button'
import { useAuth } from '../contexts/AuthContext'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip"
import { ScenarioGenerationSettingsDTO } from '@superlekcja/shared'

interface ScenarioPanelContentProps {
  settings: ScenarioGenerationSettingsDTO; 
  onSettingsChange: (settings: ScenarioGenerationSettingsDTO) => void;
  onRegenerate?: () => void;
  onUndo?: () => void;
  onRedo?: () => void;
  onCopy: () => void;
  onRevertChanges?: () => void;
  onGenerateImages?: () => void;
  canUndo?: boolean;
  canRedo?: boolean;
  isGenerating?: boolean;
  isGeneratingImages?: boolean;
  hasChanges?: boolean;
  isEditable?: boolean;
}

export function ScenarioPanelContent({
  settings,
  onSettingsChange,
  onRegenerate,
  onUndo,
  onRedo,
  onCopy,
  onRevertChanges,
  onGenerateImages,
  canUndo = false,
  canRedo = false,
  isGenerating = false,
  isGeneratingImages = false,
  hasChanges = false,
  isEditable = true,
}: ScenarioPanelContentProps) {
  const { profile, isTester } = useAuth();

  return (
    <>
      {/* Settings Form */}
      {isEditable ? (
        <div className="p-4">
          <div className="space-y-4">
            <ScenarioGenerationSettingsForm
              settings={settings}
              onSettingsChange={onSettingsChange}
              disabled={isGenerating}
              layout="vertical"
            />
            
            <div className="flex flex-col gap-2">
              <GenerateButton
                onClick={onRegenerate}
                disabled={isGenerating || !hasChanges}
                isGenerating={isGenerating}
                variant={hasChanges ? "default" : "ghost"}
                className="w-full"
              />

              {isTester && onGenerateImages && (
                <Button
                  onClick={onGenerateImages}
                  disabled={isGeneratingImages}
                  variant="outline"
                  className="w-full bg-transparent text-white border-white hover:bg-white/10"
                  data-testid="generate-images-button"
                >
                  <ImagePlus className="h-4 w-4 mr-2" />
                  {isGeneratingImages ? "Generowanie..." : "Wygeneruj obrazy"}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4">
          <p className="text-sm text-primary-foreground/80 text-center mb-4">
            Skopiuj lekcję by móc ją edytować
          </p>
          <Button
            onClick={onCopy}
            disabled={isGenerating}
            className="w-full bg-hero text-white hover:bg-hero/90 border-transparent disabled:bg-hero disabled:opacity-50 disabled:hover:bg-hero"
            data-testid="copy-button"
          >
            Skopiuj lekcję
          </Button>
        </div>
      )}

      {/* Bottom Controls */}
      <div className="border-t border-primary-foreground/20 p-4 bg-primary-foreground/5">
        <TooltipProvider>
          <div className="flex items-center h-8 justify-between">
            <div className="flex items-center gap-2">
              {isEditable && (
                <>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={onUndo}
                        disabled={!canUndo || isGenerating}
                        className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                        data-testid="scenario-undo-button"
                      >
                        <Undo className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Cofnij zmiany</p>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={onRedo}
                        disabled={!canRedo || isGenerating}
                        className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                        data-testid="scenario-redo-button"
                      >
                        <Redo className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Ponów zmiany</p>
                    </TooltipContent>
                  </Tooltip>
                </>
              )}
            </div>

            <div className="flex items-center gap-2">
              {isEditable && hasChanges && !isGenerating && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={onRevertChanges}
                      disabled={isGenerating}
                      className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                    >
                      <RotateCcw className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Cofnij wszystkie zmiany</p>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </div>
        </TooltipProvider>
      </div>
    </>
  )
} 