import { Button } from './ui/button'
import { Undo, Redo } from 'lucide-react'
import { GenerateButton } from './ui/generate-button'
import { KnowledgeDTO, KnowledgeGenerationSettingsDTO } from '@superlekcja/shared'

interface KnowledgePanelContentProps {
  onGenerate?: () => void;
  onUndo?: () => void;
  onRedo?: () => void;
  canUndo?: boolean;
  canRedo?: boolean;
  isGenerating?: boolean;
  disabled?: boolean;
  isEditable?: boolean;
  knowledge: KnowledgeDTO | null;
  settings: KnowledgeGenerationSettingsDTO; 
  onSettingsChange: (settings: KnowledgeGenerationSettingsDTO) => void;
  hasChanges?: boolean;
  scenarioId?: string;
}

export function KnowledgePanelContent({
  onGenerate,
  onUndo,
  onRedo,
  canUndo = false,
  canRedo = false,
  isGenerating = false,
  disabled = false,
  isEditable = true,
  knowledge,
  settings,
  onSettingsChange,
  hasChanges = false,
  scenarioId,
}: KnowledgePanelContentProps) {
  const scenarioMismatch = knowledge && scenarioId && knowledge.scenarioId !== scenarioId;
  const canGenerate = !knowledge || hasChanges || scenarioMismatch;

  return (
    <>
      {/* Settings */}
      {isEditable && (
        <div className="p-4 space-y-4">
          <GenerateButton
            onClick={onGenerate}
            disabled={isGenerating || !canGenerate}
            isGenerating={isGenerating}
            variant={isGenerating || !canGenerate ? "ghost" : "default"}
            className="w-full"
          />
        </div>
      )}

      {/* Bottom Controls */}
      <div className="border-t border-primary-foreground/20 p-4 bg-primary-foreground/5">
        <div className="flex items-center h-8 gap-2">
          {isEditable && (
            <>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                onClick={onUndo}
                disabled={!canUndo || isGenerating}
                data-testid="knowledge-undo-button"
              >
                <Undo className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                onClick={onRedo}
                disabled={!canRedo || isGenerating}
                data-testid="knowledge-redo-button"
              >
                <Redo className="h-4 w-4" />
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  )
} 