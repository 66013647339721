import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MillionairesData, WorksheetItemDTO } from '@superlekcja/shared';
import { api } from '../services/api';
import { Loader2 } from 'lucide-react';
import { MilionerzyGamePage } from './MilionerzyGamePage';

export function WorksheetItemPage() {
  const { lessonId, itemId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [item, setItem] = useState<WorksheetItemDTO | null>(null);

  useEffect(() => {
    async function fetchItem() {
      if (!lessonId || !itemId) return;

      try {
        setIsLoading(true);
        setError(null);
        const response = await api.get<{ status: string; data: WorksheetItemDTO }>(`/lessons/${lessonId}/worksheet/${itemId}`);
        setItem(response.data.data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Wystąpił błąd podczas ładowania zadania');
        console.error('Error fetching worksheet item:', err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchItem();
  }, [lessonId, itemId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  if (!item) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-gray-500">Nie znaleziono zadania</div>
      </div>
    );
  }

  // For millionaires items, render the game page
  if (item.type === 'millionaires') {
    return <MilionerzyGamePage title={item.title} item={item.data as MillionairesData} />;
  }

  // For all other items, render raw JSON
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-2xl font-bold mb-4">{item.title}</h1>
      <pre className="bg-gray-100 p-4 rounded-lg overflow-auto">
        {JSON.stringify(item, null, 2)}
      </pre>
    </div>
  );
} 