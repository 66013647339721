import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthModal } from '../components/auth/AuthModal'
import { useAuth } from '../contexts/AuthContext'
import { createLesson } from '../services/lesson.service'
import { ScenarioGenerationSettingsForm } from '../components/ScenarioGenerationSettingsForm'
import { GenerateButton } from '../components/ui/generate-button'
import { type ScenarioGenerationSettingsDTO, type LanguageCode } from '@superlekcja/shared'
import { Textarea } from '../components/ui/textarea'
import { useToast } from '../components/ui/use-toast'
import { useProfileDialog } from '../contexts/ProfileDialogProvider'
import { LanguageSelector } from '../components/ui/language-selector'
import { samplePrompts } from '../data/samplePrompts'
import { useTypingAnimation } from '../hooks/useTypingAnimation'
import { SuggestionsPanel } from '../components/SuggestionsPanel'
import { useCyclingText } from '../hooks/useCyclingText'
import { cn } from '../lib/utils'
import { VideoDemo } from '../components/VideoDemo'
import { BlogBanner } from '@/components/BlogBanner'

const roleWords = ['Nauczycielu', 'Rodzicu', 'Korepetytorze', 'Nauczycielko', 'Instruktorze', 'Nianiu', 'Wychowawco']

export function HomePage() {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()
  const { toast } = useToast()
  const { openDialog : showProfileDialog } = useProfileDialog()
  const [searchParams] = useSearchParams()

  const [prompt, setPrompt] = useState('')
  const [isGenerating, setIsGenerating] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [language, setLanguage] = useState<LanguageCode>('pl')
  const [isFocused, setIsFocused] = useState(false)
  const [settings, setSettings] = useState<ScenarioGenerationSettingsDTO>({
    duration: 45,
    difficulty: 2
  })

  const { currentWord, isVisible } = useCyclingText(roleWords)

  const placeholderText = useTypingAnimation({
    texts: samplePrompts,
    typingSpeed: 50,
    deletingSpeed: 30,
    delayAfterTyping: 3000,
    isFocused
  })

  // Handle invitation parameter
  useEffect(() => {
    const invitedBy = searchParams.get('invitedBy')
    if (invitedBy && !isAuthenticated) {
      sessionStorage.setItem('invitedBy', invitedBy)
      setShowAuthModal(true)
    }
  }, [searchParams, isAuthenticated])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!isAuthenticated) {
      setShowAuthModal(true)
      return
    }

    if (!prompt.trim()) {
      return
    }

    try {
      setIsGenerating(true)
      const id = await createLesson({
        prompt: {
          userPrompt: prompt,
          settings
        },
        language
      })
      navigate(`/lessons/${id}`)
    } catch (error) {
      console.error(error)
      if (error instanceof Error && error.message === 'INSUFFICIENT_CREDITS') {
        showProfileDialog()
        toast({
          variant: "destructive",
          title: "Brak kredytów",
          description: "Kup więcej kredytów aby kontynuować generowanie scenariuszy.",
        })
      } else {
        toast({
          variant: "destructive",
          title: "Błąd",
          description: "Nie udało się wygenerować lekcji. Spróbuj ponownie.",
        })
      }
    } finally {
      setIsGenerating(false)
    }
  }

  const handleSettingsChange = (newSettings: ScenarioGenerationSettingsDTO) => {
    setSettings(newSettings)
  }

  return (
    <div className="">
      <BlogBanner />
      <div className="w-full max-w-4xl mx-auto px-4 sm:px-6">
        <div className="flex flex-col items-center">
          <div className="relative mb-8 sm:mb-12 mt-8 space-y-2 pt-10">
            <h1 className="pl-2 text-3xl sm:text-5xl font-bold text-white">
              Witaj <span style={{transitionDuration:'2000ms'}} className={cn(
                "text-gradient transition-opacity",
                isVisible ? "opacity-100" : "opacity-0"
              )}>{currentWord}</span>
            </h1>
            <h2 className="text-2xl sm:text-4xl font-semibold text-white">
              O czym chcesz dziś <span className="text-gradient">uczyć?</span>
            </h2>
          </div>
        </div>

        <div className="max-w-3xl mx-auto">
          <form onSubmit={(e) => handleSubmit(e)} className="mb-8">
            <div className="flex flex-col gap-4">
              <div className="bg-primary rounded-xl shadow-sm text-primary-foreground p-4 sm:p-8">
                <Textarea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  placeholder={placeholderText}
                  data-testid="prompt-textarea"
                  rows={4}
                  className="resize-none border-0 p-4 !text-lg shadow-none bg-white rounded-lg text-foreground mb-4"
                />
                
                <div className="flex flex-wrap items-end gap-4">
                  <div className="flex-1 min-w-[280px] flex gap-4 items-end">
                    <ScenarioGenerationSettingsForm
                      settings={settings}
                      onSettingsChange={handleSettingsChange}
                      layout="horizontal"
                      className="flex-1"
                    />
                    <LanguageSelector
                      value={language}
                      onValueChange={setLanguage}
                      disabled={isGenerating}
                    />
                  </div>
                  <GenerateButton
                    disabled={isGenerating || !prompt.trim()}
                    isGenerating={isGenerating}
                    className="w-full sm:w-48 shrink-0"
                    variant="default"
                  />
                </div>
              </div>
            </div>
          </form>

          <SuggestionsPanel />
        </div>

        <AuthModal
          isOpen={showAuthModal}
          onClose={() => setShowAuthModal(false)}
        />
      </div>
      <VideoDemo />
    </div>
  )
} 