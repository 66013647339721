import { useState, useEffect } from 'react';

interface TypeAnimationConfig {
  texts: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  delayAfterTyping?: number;
  isFocused?: boolean;
}

function getRandomIndex(max: number, exclude?: number): number {
  if (max <= 1) return 0;
  let randomIndex;
  do {
    randomIndex = Math.floor(Math.random() * max);
  } while (randomIndex === exclude);
  return randomIndex;
}

export function useTypingAnimation({ 
  texts, 
  typingSpeed = 50, 
  deletingSpeed = 30,
  delayAfterTyping = 3000,
  isFocused = false
}: TypeAnimationConfig) {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(() => getRandomIndex(texts.length));
  const [isTyping, setIsTyping] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (isFocused) {
      setCurrentText('');
      return;
    }

    let timeout: NodeJS.Timeout;

    const currentFullText = texts[currentIndex];

    if (isTyping) {
      if (currentText.length < currentFullText.length) {
        // Still typing
        timeout = setTimeout(() => {
          setCurrentText(currentFullText.slice(0, currentText.length + 1));
        }, typingSpeed);
      } else {
        // Finished typing, wait before deleting
        timeout = setTimeout(() => {
          setIsTyping(false);
          setIsDeleting(true);
        }, delayAfterTyping);
      }
    } else if (isDeleting) {
      if (currentText.length > 0) {
        // Still deleting
        timeout = setTimeout(() => {
          setCurrentText(currentText.slice(0, -1));
        }, deletingSpeed);
      } else {
        // Finished deleting, move to next text
        setIsDeleting(false);
        setIsTyping(true);
        setCurrentIndex(prevIndex => getRandomIndex(texts.length, prevIndex));
      }
    }

    return () => clearTimeout(timeout);
  }, [currentText, currentIndex, isTyping, isDeleting, texts, typingSpeed, deletingSpeed, delayAfterTyping]);

  return currentText;
} 