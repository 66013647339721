import { AxiosError } from 'axios';

import { CreateScenarioParamsDTO, ApiSuccess } from '@superlekcja/shared';
import { ModelStorage } from '@/lib/modelStorage';
import type { LessonDTO, CreateScenarioOptions, ScenarioDTO, SuggestionDTO, LessonSummaryDTO, CreateKnowledgeParamsDTO, ScenarioUpdateDTO, KnowledgeUpdateDTO, WorksheetUpdateDTO, WorksheetItemType } from '@superlekcja/shared';
import { StreamEventDTO } from '@superlekcja/shared';
import { baseURL, getAuthToken } from './api';
import { api, INSUFFICIENT_CREDITS } from './api';


function wrapError(error: any) {
  if (error instanceof AxiosError && error.response?.status === 402) {
    return new Error(INSUFFICIENT_CREDITS);
  }
  if (error instanceof AxiosError && error.response?.data) {
    return new Error(error.response.data.message || 'Failed to create scenario');
  }
  return error;
}


export async function createScenario(lessonId: string, options: CreateScenarioOptions): Promise<ScenarioUpdateDTO> {
  try {
    const modelStorage = ModelStorage.getInstance();
    const params: CreateScenarioParamsDTO = {
      ...options,
      model: modelStorage.getSelectedModel()
    };

    const response = await api.post<ApiSuccess<ScenarioUpdateDTO>>(`/lessons/${lessonId}/scenario`, params);
    return response.data.data;
  } catch (error) {
    throw wrapError(error);
  }
}

export async function createKnowledge(lessonId: string, options: CreateKnowledgeParamsDTO): Promise<KnowledgeUpdateDTO> {
  try {
    const modelStorage = ModelStorage.getInstance();
    const params: CreateKnowledgeParamsDTO = {
      prompt: {
        ...options.prompt,
      },
      model: modelStorage.getSelectedModel()
    };

    const response = await api.post<ApiSuccess<KnowledgeUpdateDTO>>(`/lessons/${lessonId}/knowledge`, params);
    return response.data.data;
  } catch (error) {
    throw wrapError(error);
  }
}

export async function createLesson(options: CreateScenarioOptions): Promise<string> {
  try {
    const modelStorage = ModelStorage.getInstance();
    const params: CreateScenarioParamsDTO = {
      ...options,
      model: modelStorage.getSelectedModel()
    };

    const response = await api.post<ApiSuccess<LessonDTO>>('/lessons', params);
    return response.data.data.id;
  } catch (error) {
    throw wrapError(error);
  }
}

export async function getLesson(id: string): Promise<LessonDTO> {
  try {
    const response = await api.get<ApiSuccess<LessonDTO>>(`/lessons/${id}`);
    return response.data.data;
  } catch (error) {
    throw wrapError(error);
  }
}

export async function copyLesson(id: string): Promise<LessonDTO> {
  const response = await api.post<ApiSuccess<LessonDTO>>(`/lessons/${id}/copy`);
  return response.data.data;
}

export async function getScenario(id: string): Promise<ScenarioDTO> {
  const response = await api.get<ApiSuccess<ScenarioDTO>>(`/scenarios/${id}`);
  return response.data.data;
}

export interface GetSuggestionsResponse {
  suggestions: SuggestionDTO[];
  page: number;
  itemsPerPage: number;
}

export async function getSuggestions(page:number, itemsPerPage:number): Promise<GetSuggestionsResponse> {
  const response = await api.get<ApiSuccess<GetSuggestionsResponse>>('/suggestions', {
    params: {
      page,
      itemsPerPage
    }
  });
  return response.data.data;
}

export async function getUserLessons(): Promise<LessonSummaryDTO[]> {
  const response = await api.get<ApiSuccess<LessonSummaryDTO[]>>('/lessons');
  return response.data.data;
}

export const generateWorksheetItem = async (
  lessonId: string,
  type: WorksheetItemType,
  replacePuzzleId?: string
): Promise<WorksheetUpdateDTO> => {
  try {
    const response = await api.post<ApiSuccess<WorksheetUpdateDTO>>(`/lessons/${lessonId}/worksheet/${replacePuzzleId ?? ''}`, {
      type
    });
    return response.data.data;
  } catch (e: any) {
    throw wrapError(e);
  }
};

export const removeWorksheetItem = async (lessonId: string, itemId: string): Promise<WorksheetUpdateDTO> => {
  try {
    const response = await api.delete<ApiSuccess<WorksheetUpdateDTO>>(`/lessons/${lessonId}/worksheet/${itemId}`);
    return response.data.data;
  } catch (e: any) {
    throw wrapError(e);
  }
};

export const navigateWorksheetVersion = async (lessonId: string, worksheetId: string): Promise<WorksheetUpdateDTO> => {
  try {
    const response = await api.put<ApiSuccess<WorksheetUpdateDTO>>(`/lessons/${lessonId}/worksheet/version/${worksheetId}`);
    return response.data.data;
  } catch (e: any) {
    throw wrapError(e);
  }
};

export const navigateScenarioVersion = async (lessonId: string, scenarioId: string): Promise<ScenarioUpdateDTO> => {
  try {
    const response = await api.put<ApiSuccess<ScenarioUpdateDTO>>(`/lessons/${lessonId}/scenario/version/${scenarioId}`);
    return response.data.data;
  } catch (e: any) {
    throw wrapError(e);
  }
};

export const navigateKnowledgeVersion = async (lessonId: string, knowledgeId: string): Promise<KnowledgeUpdateDTO> => {
  try {
    const response = await api.put<ApiSuccess<KnowledgeUpdateDTO>>(`/lessons/${lessonId}/knowledge/version/${knowledgeId}`);
    return response.data.data;
  } catch (e: any) {
    throw wrapError(e);
  }
};

export interface StreamCallbacks<T> {
  onStart?: () => void;
  onProgress?: (progress: string) => void;
  onContent?: (content: string) => void;
  onComplete?: (entity: T) => void;
  onError?: (error: string) => void;
}

export async function streamContent<T>(
  lessonId: string,
  source: 'scenario' | 'knowledge',
  callbacks: StreamCallbacks<T>,
  signal?: AbortSignal
): Promise<void> {
  callbacks.onStart?.();

  try {
    const token = await getAuthToken();
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Accept': 'text/event-stream'
    });

    // this is GET. we can also use POST to create & stream!
    const response = await fetch(`${baseURL}/lessons/${lessonId}/${source}`, {
      signal,
      headers
    });

    if (!response.ok) {
      const error = await response.json();
      callbacks.onError?.(error.message || 'Failed to stream scenario');
      throw new Error(error.message || 'Failed to stream scenario');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Response body is not readable');
    }

    const decoder = new TextDecoder();
    let buffer = '';

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer

        for (const line of lines) {
          if (!line.trim() || !line.startsWith('data: ')) continue;

          const eventData = line.slice(6); // Remove 'data: ' prefix
          const { type, data } = JSON.parse(eventData) as StreamEventDTO<T>;

          switch (type) {
            case 'progress':
              callbacks.onProgress?.(data);
              break;
            case 'chunk':
              callbacks.onContent?.(data);
              break;
            case 'completed':
              callbacks.onComplete?.(data);
              return; // End the stream processing
            case 'error':
              callbacks.onError?.(data);
              return; // End the stream processing
            default:
              //ignore unkown event
          }
        }
      }
    } finally {
      reader.releaseLock();
    }
  } catch (error) {
    callbacks.onError?.(error instanceof Error ? error.message : `${(error as any)}`);
  }
}

export interface GeneratedImage {
  title: string;
  description: string;
  url: string;
}

export async function generateImagesForScenario(lessonId: string): Promise<GeneratedImage[]> {
  try {
    const response = await api.get<ApiSuccess<GeneratedImage[]>>(`/lessons/${lessonId}/scenario/images`);
    return response.data.data;
  } catch (error) {
    throw wrapError(error);
  }
}

