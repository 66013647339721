interface CookieConsentContentProps {
  onAccept?: () => void
  onReject?: () => void
  showButtons?: boolean
  showTitle?: boolean
}

export function CookieConsentContent({ onAccept, onReject, showButtons = true, showTitle = true}: CookieConsentContentProps) {
  const [currentStatus] = useState(privacyService.getCookieConsent())
  
  return (
    <div className="space-y-4">
      <div className="space-y-2">
        {showTitle && <h3 className="font-semibold text-gray-900 text-lg">
          Polityka prywatności i pliki cookie
        </h3>}
        <div className="space-y-3 text-sm text-gray-600">
          <p>
            Ta strona używa plików cookie oraz innych technologii do analizy ruchu, 
            monitorowania błędów i personalizacji treści.
          </p>
          <p>
            Niektóre dane mogą być przetwarzane poza terenem UE przez naszych dostawców 
            (Google Analytics, Sentry, dostawcy AI) z zachowaniem odpowiednich zabezpieczeń.
          </p>
          <p>
            Korzystając z naszej strony, zgadzasz się na wykorzystanie plików cookie 
            oraz przetwarzanie danych zgodnie z naszą polityką prywatności.
          </p>
          <p className="text-sm font-medium text-amber-600">
            Uwaga: Wyrażenie zgody jest wymagane do korzystania z pełnej funkcjonalności strony, 
            w tym z funkcji AI oraz personalizacji treści.
          </p>
        </div>
      </div>

      {currentStatus && (
            <div className="mt-4 text-sm text-muted-foreground">
              Obecny status: <span className="font-medium">{currentStatus === 'accepted' ? 'Zaakceptowane' : 'Odrzucone'}</span>
            </div>
          )}

      {showButtons && (
        <div className="flex flex-col gap-2 sm:flex-row sm:justify-center">
          <Button 
            variant="default" 
            onClick={onAccept}
            className="flex-1 max-w-[200px]"
            data-testid="accept-cookies"
          >
            Zgadzam się
          </Button>
          <Button 
            variant="outline" 
            onClick={onReject}
            className="flex-1 max-w-[200px]"
            data-testid="reject-cookies"
          >
            Nie zgadzam się
          </Button>
        </div>
      )}
    </div>
  )
}

import { privacyService } from '@/services/privacy.service'
import { useState } from 'react'
import { Button } from './ui/button' 