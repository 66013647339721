import { Button } from './ui/button'
import { Plus, Undo, Redo, Eye, EyeOff } from 'lucide-react'
import { WorksheetItemDTO, WorksheetDTO, isWordsearchEnabled } from '@superlekcja/shared'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip'
import { useNavigate } from 'react-router-dom'

const AVAILABLE_PUZZLES = [
  { id: 'crossword', name: 'Krzyżówka' },
  { id: 'quiz', name: 'Quiz' },
  { id: 'wordsearch', name: 'Wykreślanka' },
  { id: 'riddle', name: 'Zagadki' },
  { id: 'problem-solving', name: 'Rozwiąż problem' },
  { id: 'memory', name: 'Technika pamięciowa' },
  { id: 'millionaires', name: 'Kujonerzy' },
] as const;

interface WorksheetPanelContentProps {
  onGeneratePuzzle: (type: 'crossword' | 'quiz' | 'wordsearch' | 'riddle' | 'problem-solving' | 'memory' | 'millionaires') => void;
  isGeneratingPuzzle: boolean;
  showSolutions: boolean;
  onShowSolutionsChange: (show: boolean) => void;
  puzzles?: WorksheetItemDTO[];
  disabled?: boolean;
  isEditable?: boolean;
  worksheet: WorksheetDTO | null;
  onUndo?: () => void;
  onRedo?: () => void;
  canUndo?: boolean;
  canRedo?: boolean;
  language?: string;
  lessonId?: string;
}

export function WorksheetPanelContent({
  onGeneratePuzzle,
  isGeneratingPuzzle = false,
  showSolutions,
  onShowSolutionsChange,
  puzzles = [],
  disabled = false,
  isEditable = true,
  worksheet,
  onUndo,
  onRedo,
  canUndo = false,
  canRedo = false,
  language = 'pl',
  lessonId
}: WorksheetPanelContentProps) {
  const navigate = useNavigate();

  // Count puzzles by type
  const puzzleCounts = puzzles.reduce((acc, puzzle) => {
    acc[puzzle.type] = (acc[puzzle.type] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Helper function to check if a puzzle type is disabled
  const isPuzzleDisabled = (puzzleId: string): boolean => {
    if (isGeneratingPuzzle || disabled) return true;
    if (puzzleId === 'wordsearch' && !isWordsearchEnabled(language)) return true;
    return false;
  };

  // Helper function to get tooltip content for disabled puzzles
  const getTooltipContent = (puzzleId: string): string | null => {
    if (puzzleId === 'wordsearch' && !isWordsearchEnabled(language)) {
      return 'Wykreślanka nie jest dostępna dla tego języka';
    }
    return null;
  };

  return (
    <>
      {/* Puzzle List */}
      {isEditable && (
        <div className="p-4">
          <div className="space-y-2">
            {AVAILABLE_PUZZLES.map(puzzle => {
              const count = puzzleCounts[puzzle.id] || 0;
              const tooltipContent = getTooltipContent(puzzle.id);
              const isDisabled = isPuzzleDisabled(puzzle.id);

              const button = (
                <Button
                  variant="outline"
                  size="icon"
                  className="h-8 w-8 bg-primary border-primary-foreground text-primary-foreground hover:bg-hero hover:border-hero hover:text-white active:bg-hero/90 transition-colors disabled:opacity-50"
                  onClick={(e) => {
                    e.stopPropagation();
                    onGeneratePuzzle(puzzle.id);
                  }}
                  disabled={isDisabled}
                  data-testid={`generate-${puzzle.id}-button`}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              );

              return (
                <div 
                  key={puzzle.id}
                  className={`flex items-center justify-between py-2 px-3 rounded-lg cursor-pointer transition-colors`}
                >
                  <div className="flex items-center gap-3 flex-1">
                    <label
                      htmlFor={puzzle.id}
                      className="text-sm select-none text-primary-foreground"
                    >
                      {puzzle.name}
                    </label>
                  </div>
                  <div className="flex items-center gap-2">
                    {count > 0 && (
                      <div className="px-1.5 py-0.5 text-xs font-medium rounded-full bg-primary-foreground/20 text-primary-foreground">
                        {count}
                      </div>
                    )}
                    {tooltipContent ? (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            {button}
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{tooltipContent}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : button}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Bottom Controls */}
      <div className="border-t border-primary-foreground/20 p-4 bg-primary-foreground/5">
        <div className="flex items-center h-8 justify-between">
          <div className="flex items-center gap-2">
            {isEditable && (
              <>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                  onClick={onUndo}
                  disabled={!canUndo || isGeneratingPuzzle}
                  data-testid="undo-button"
                >
                  <Undo className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
                  onClick={onRedo}
                  disabled={!canRedo || isGeneratingPuzzle}
                  data-testid="redo-button"
                >
                  <Redo className="h-4 w-4" />
                </Button>
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
              onClick={() => onShowSolutionsChange(!showSolutions)}
              data-testid="show-solutions-button"
            >
              {showSolutions ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
} 