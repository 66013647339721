import { useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from './ui/select'
import { cn } from '@/lib/utils'
import { AvailableModels } from '@superlekcja/shared'
import { ModelStorage } from '@/lib/modelStorage'
import { useAuth } from '../contexts/AuthContext'

interface ModelSelectorProps {
  className?: string
}

// Convert AvailableModels to array of objects with full model string as id
const MODELS = Object.entries(AvailableModels).flatMap(([provider, models]) =>
  models.map(model => ({
    id: `${provider}:${model}`,
    displayName: model, // Just the model name for display
    provider: provider.charAt(0).toUpperCase() + provider.slice(1)
  }))
)

export function ModelSelector({ className }: ModelSelectorProps) {
  const modelStorage = ModelStorage.getInstance();
  const [selectedModel, setSelectedModel] = useState(() => modelStorage.getSelectedModel());
  const { user } = useAuth();

  const handleModelChange = (value: string) => {
    setSelectedModel(value);
    modelStorage.setSelectedModel(value);
  };

  const currentModel = MODELS.find(model => model.id === selectedModel);

  // Only show for users with tester role
  if (!user?.email?.endsWith('@superlekcja.pl')) {
    return null;
  }

  return (
    <div 
      className={cn(
        "print:hidden",
        "px-2 py-0.5 text-xs font-medium rounded-full",
        "border-0 backdrop-blur-sm",
        className
      )}
    >
      <Select value={selectedModel} onValueChange={handleModelChange}>
        <SelectTrigger className="h-5 text-xs bg-transparent border-0 text-primary-foreground/80 hover:bg-primary-foreground/10 hover:text-primary-foreground p-0 shadow-none focus:ring-0 focus:ring-offset-0">
          <SelectValue>{currentModel?.displayName}</SelectValue>
        </SelectTrigger>
        <SelectContent>
          {Object.values(MODELS.reduce((acc, model) => {
            if (!acc[model.provider]) {
              acc[model.provider] = []
            }
            acc[model.provider].push(model)
            return acc
          }, {} as Record<string, typeof MODELS[number][]>)).map((models, index) => (
            <SelectGroup key={index}>
              <SelectLabel className="text-xs">{models[0].provider}</SelectLabel>
              {models.map(model => (
                <SelectItem key={model.id} value={model.id} className="text-xs">
                  {model.displayName}
                </SelectItem>
              ))}
            </SelectGroup>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
} 