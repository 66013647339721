import { useRef, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../ui/dialog'
import { useAuth } from '../../contexts/AuthContext'
import { CreditCard, LogOut, Loader2, Smartphone, Copy, Check, Sparkles } from 'lucide-react'
import { useEffect } from 'react'
import { profileService } from '../../services/profile.service'
import { Button } from '../ui/button'
import { ConfirmPurchaseDialog } from './ConfirmPurchaseDialog'
import { useToast } from '../ui/use-toast'
import { LogoutConfirmModal } from './LogoutConfirmModal'
import { useSearchParams } from 'react-router-dom'
import { 
  AnalyticsEvent,
  trackPurchaseCredits
} from '../../utils/analytics'
import { PLANS } from '@superlekcja/shared'
import { User } from '@supabase/supabase-js'

const paymentsEnabled = import.meta.env.VITE_PAYMENTS_ENABLED === 'true'

interface UserProfileDialogProps {
  isOpen: boolean
  onClose: () => void
  onLogout?: () => void
}

export function UserProfileDialog({ isOpen, onClose, onLogout }: UserProfileDialogProps) {
  const { profile, user, logout, refreshProfile } = useAuth()
  const { toast } = useToast()
  const [purchaseCreditsAmount, setPurchaseCreditsAmount] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPendingTransaction, setIsPendingTransaction] = useState(false)
  const [isBlikPayment, setIsBlikPayment] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    if (isOpen) {
      refreshProfile();
    }
  }, [isOpen, refreshProfile]);

  // Check if there's a pending transaction
  useEffect(() => {
    const transactionId = searchParams.get('transactionPending');
    setIsPendingTransaction(!!transactionId);
  }, [searchParams]);

  const handleBuyCredits = (credits: number) => {
    setPurchaseCreditsAmount(credits);
  };

  const handleConfirmPurchase = async (paymentType: 'blik' | 'other', blikCode?: string) => {
    if (!purchaseCreditsAmount) return;

    setIsLoading(true);
    try {
      const response = await profileService.purchaseCredits(purchaseCreditsAmount, paymentType, blikCode);

      const amount = PLANS[purchaseCreditsAmount as keyof typeof PLANS]
      trackPurchaseCredits({
        success: true,
        amount,
        currency: "PLN"
      });

      if (response.redirectUrl) {
        // For standard payment methods, redirect to PayU
        window.location.href = response.redirectUrl;
      } else {
        // For BLIK payments, start polling for transaction status
        searchParams.set('transactionPending', response.extOrderId);
        setSearchParams(searchParams);
        setPurchaseCreditsAmount(null); // Close the purchase dialog
        setIsPendingTransaction(true);
        setIsBlikPayment(true);
      }
    } catch (error) {
      console.error('Failed to purchase credits:', error);
      toast({
        variant: "destructive",
        title: "Błąd",
        description: "Nie udało się rozpocząć procesu płatności. Spróbuj ponownie później.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    await logout()
    setShowLogoutConfirm(false)
    onClose()
    onLogout?.()
  }

  const inviteLink = user?.id ? `${window.location.protocol}//${window.location.host}/?invitedBy=${encodeURIComponent(user.id)}` : null

  const handleCopyInviteLink = () => {
    if (inviteLink) {
      navigator.clipboard.writeText(inviteLink)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
      toast({
        title: "Skopiowano link",
        description: "Link do zaproszenia został skopiowany do schowka",
      })
    }
  }

    return (
      <>
        <Dialog open={isOpen} onOpenChange={onClose}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Twój profil</DialogTitle>
              <DialogDescription>
                Sprawdź stan swojego konta i dostępne kredyty
              </DialogDescription>
            </DialogHeader>

            <div className="py-4">
              <div className="flex items-center justify-between p-4 bg-muted rounded-lg">
                <div className="flex items-center gap-3">
                  <CreditCard className="h-5 w-5 text-muted-foreground" />
                  <span className="text-sm font-medium">Dostępne kredyty</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-2xl font-bold" data-testid="credits-display">
                    {profile?.credits ?? 0}
                  </span>
                  {isPendingTransaction && (
                    <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
                  )}
                </div>
              </div>

              {paymentsEnabled ? (
                <div className="mt-4 space-y-2">
                  <h3 className="text-sm font-medium text-muted-foreground">
                    {isPendingTransaction ? (
                      <div className="space-y-2">
                        <span className="flex items-center gap-2">
                          <Loader2 className="h-4 w-4 animate-spin" />
                          Przetwarzanie płatności...
                        </span>
                        {isBlikPayment && (
                          <div className="flex items-center gap-2 text-xs text-muted-foreground bg-muted p-2 rounded">
                            <Smartphone className="h-4 w-4" />
                            Potwierdź płatność w swojej aplikacji bankowej
                          </div>
                        )}
                      </div>
                    ) : (
                      'Kup więcej kredytów'
                    )}
                  </h3>
                  <div className="grid grid-cols-3 gap-2">
                    <Button
                      variant="outline"
                      onClick={() => handleBuyCredits(100)}
                      className="w-full h-[72px] flex flex-col items-center justify-center"
                      disabled={isPendingTransaction}
                    >
                      <span className="text-xl">+100</span>
                      <span className="text-sm">10 PLN</span>
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => handleBuyCredits(200)}
                      className="w-full h-[72px] flex flex-col items-center justify-center"
                      disabled={isPendingTransaction}
                    >
                      <span className="text-xl">+200</span>
                      <span className="text-sm">20 PLN</span>
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => handleBuyCredits(500)}
                      className="w-full h-[72px] flex flex-col items-center justify-center"
                      disabled={isPendingTransaction}
                    >
                      <span className="text-xl">+500</span>
                      <span className="text-sm">50 PLN</span>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="mt-4 p-4 bg-primary/5 border border-primary/10 rounded-lg">
                  <div className="flex items-center gap-2">
                    <Sparkles className="h-4 w-4 text-primary shrink-0" />
                    <p className="text-sm font-medium">
                      Na czas beta-testów, twoje kredyty będą automatycznie doładowywane co miesiąc!
                    </p>
                  </div>
                </div>
              )}

              <div className="mt-8 space-y-4 pt-4 border-t">
                {inviteLink && <div className="space-y-2">
                  <h3 className="text-sm font-medium text-muted-foreground">
                    Link polecający
                  </h3>
                  <div className="flex items-center gap-2">
                    <code className="flex-1 p-2 bg-muted rounded text-xs break-all">
                      {inviteLink}
                    </code>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={handleCopyInviteLink}
                      className="shrink-0"
                    >
                      {isCopied ? (
                        <Check className="h-4 w-4 text-green-500" />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                  <p className="text-xs text-muted-foreground">
                    Zaproś znajomych wysyłając ten link - otrzymają 100 kredytów na start,
                    a Ty 50 za każdą osobę, która się zarejestruje.
                  </p>
                </div>}

                <Button
                  variant="ghost"
                  className="w-full justify-start text-destructive hover:text-destructive hover:bg-destructive/10"
                  onClick={() => setShowLogoutConfirm(true)}
                  data-testid="logout-button"
                  disabled={isPendingTransaction}
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  Wyloguj się
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {paymentsEnabled && (
          <ConfirmPurchaseDialog
            isOpen={purchaseCreditsAmount !== null}
            onClose={() => setPurchaseCreditsAmount(null)}
            onConfirm={handleConfirmPurchase}
            amount={purchaseCreditsAmount ?? 0}
            isLoading={isLoading}
          />
        )}

        <LogoutConfirmModal
          isOpen={showLogoutConfirm}
          onClose={() => setShowLogoutConfirm(false)}
          onConfirm={handleLogout}
        />
      </>
    )
} 
