import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog"

interface GeneratedImage {
  title: string;
  description: string;
  url: string;
}

interface ImageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  images: GeneratedImage[];
}

export function ImageDialog({ isOpen, onClose, images }: ImageDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>Wygenerowane obrazy</DialogTitle>
        </DialogHeader>
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col gap-6 p-4">
            {images.map((image, index) => (
              <div key={index} className="space-y-2">
                <img
                  src={image.url}
                  alt={image.title}
                  className="w-full object-cover rounded-lg"
                />
                <h3 className="font-semibold text-sm">{image.title}</h3>
                <p className="text-sm text-muted-foreground">{image.description}</p>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 