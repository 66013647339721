import { businessInfo } from '../config/business-info'
import privacyPolicyContent from '../content/privacy-policy.md?raw'
import { SimpleMarkdownView } from '../components/SimpleMarkdownView'
import { Link } from 'react-router-dom'

export function PrivacyPolicyPage() {
  const content = privacyPolicyContent
    .replace(/{domain}/g, businessInfo.website.domain)
    .replace(/{name}/g, businessInfo.name)
    .replace(/{businessName}/g, businessInfo.businessName)
    .replace(/{street}/g, businessInfo.address.street)
    .replace(/{number}/g, businessInfo.address.number)
    .replace(/{fullAddress}/g, businessInfo.address.fullAddress)
    .replace(/{nip}/g, businessInfo.legal.nip)
    .replace(/{regon}/g, businessInfo.legal.regon)
    .replace(/{email}/g, businessInfo.contact.email)

  return (
    <div className="container py-12 px-4 max-w-4xl mx-auto">
      <Link to="/" className="block mb-4 text-sm text-muted-foreground hover:text-primary transition-colors">
        ← Powrót do strony głównej
      </Link>
      <div className="bg-white/80 backdrop-blur-sm rounded-xl p-8 shadow-sm">
        <SimpleMarkdownView 
          content={content} 
          className="prose prose-slate max-w-none [&_h1]:text-3xl [&_h1]:font-bold [&_h1]:mb-2 [&_h1]:text-primary [&_h1]:text-center [&_h2]:text-xl [&_h2]:text-primary/80 [&_h2]:text-center [&_h2]:mb-8"
        />
      </div>
    </div>
  )
} 