import { CookieConsentContent } from '../CookieConsentContent'
import { privacyService } from '../../services/privacy.service'

interface AuthCookieConsentProps {
  onAccept: () => void
  onReject: () => void
}

export function AuthCookieConsent({ onAccept, onReject }: AuthCookieConsentProps) {
  const handleAccept = () => {
    privacyService.setCookieConsent('accepted')
    onAccept()
  }

  const handleReject = () => {
    privacyService.setCookieConsent('rejected')
    onReject()
  }

  return (
    <div className="px-6 py-4">
      <CookieConsentContent
        onAccept={handleAccept}
        onReject={handleReject}
      />
    </div>
  )
} 