import React, { ReactNode } from 'react';

interface HeaderProps {
  level: 1 | 2 | 3;
  title: string;
  icon?: ReactNode;
  actionSlot?: ReactNode;
  className?: string;
}

export function Header({
  level,
  title,
  icon,
  actionSlot,
  className = "",
}: HeaderProps) {
  const Tag = `h${level}` as const;

  const getFontClass = (level: number) => {
    switch (level) {
      case 1:
        return 'text-2xl font-bold';
      case 2:
        return 'text-xl font-semibold';
      case 3:
        return 'text-lg font-medium';
      default:
        return '';
    }
  };

  return (
    <Tag className={`relative group ${getFontClass(level)} ${className}`}>
      <div className="absolute inset-0 bg-gray-100/80 group-hover:bg-gray-200/80 border border-gray-100 rounded transition-all -inset-y-1 -inset-x-2 print:hidden" />
      <div className="relative flex items-center justify-between gap-2">
        <span className="flex items-center gap-2">
          {icon && <span className="text-gray-500">{icon}</span>}
          {title}
        </span>
        {actionSlot && (
          <span className="flex-shrink-0 print:hidden">
            {actionSlot}
          </span>
        )}
      </div>
    </Tag>
  );
} 