import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { HeaderActionButton } from './HeaderActionButton'
import { Header } from './Header'
import type { Components } from 'react-markdown'
import type { AlterationDTO } from '@superlekcja/shared'
import { ShieldCheck } from 'lucide-react'

interface MarkdownViewProps {
  content: string
  onHeaderAction?: (modification: AlterationDTO | null) => void
  activeModifications?: AlterationDTO[]
}

type HeaderTag = keyof JSX.IntrinsicElements & ('h1' | 'h2' | 'h3')

export function MarkdownView({ 
  content, 
  onHeaderAction,
  activeModifications = []
}: MarkdownViewProps) {
  const createHeaderComponent = (level: HeaderTag): Components[HeaderTag] => {
    return function HeaderComponent({ node, children, ...props }) {
      const title = children?.toString() || ''
      const headerLevel = parseInt(level.charAt(1))
      
      const icon = title === 'Podstawa programowa' ? <ShieldCheck className="h-5 w-5" /> : undefined

      return (
        <Header
          level={headerLevel as 1 | 2 | 3}
          title={title}
          icon={icon}
          actionSlot={
            onHeaderAction && (
              <HeaderActionButton
                title={title}
                level={headerLevel as 1 | 2 | 3}
                onAction={onHeaderAction}
                activeModifications={activeModifications}
              />
            )
          }
          {...props}
        />
      )
    }
  }

  const components: Components = {
    h1: createHeaderComponent('h1'),
    h2: createHeaderComponent('h2'),
    h3: createHeaderComponent('h3'),
    a: ({ node, children, href, ...props }) => (
      <a 
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    ),
  }

  return (
    <div className="relative">
      <div className="prose prose-slate max-w-none" data-testid="lesson-content">
        <ReactMarkdown 
          remarkPlugins={[remarkGfm]}
          components={components}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  )
} 