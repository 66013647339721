import { Button } from './button'
import { ChevronLeft, ChevronRight, LucideIcon } from 'lucide-react'
import { ReactNode } from 'react'

interface ControlPanelProps {
  title: string
  icon: LucideIcon
  isOpen: boolean
  isActive: boolean
  onToggle: () => void
  onChevronClick: () => void
  children: ReactNode
  badge?: number
  disabled?: boolean
}

export function ControlPanel({
  title,
  icon: Icon,
  isOpen,
  isActive,
  onToggle,
  onChevronClick,
  children,
  badge,
  disabled = false,
}: ControlPanelProps) {
  return (
    <div className={`bg-primary text-primary-foreground rounded-lg shadow-lg overflow-hidden ${isOpen ? 'min-w-48' : ''}`}
      data-testid={`${title.toLowerCase()}-panel`}>
      {/* Header */}
      <div
        className={`px-4 py-3 border-b border-primary-foreground/20 flex items-center gap-2 justify-between ${!disabled ? 'cursor-pointer hover:bg-primary-foreground/10' : 'opacity-50'
          } ${isActive ? 'bg-primary-foreground/10' : ''}`}
        onClick={!disabled ? onToggle : undefined}
      >

        <Icon className={`flex-shrink-0 h-4 w-4 ${isActive ? 'text-primary-foreground' : 'text-primary-foreground/80'}`} />
        <h2 className={`whitespace-nowrap overflow-hidden flex-shrink font-medium ${isActive ? 'text-primary-foreground' : 'text-primary-foreground/80'}`}>{title}</h2>
        <div className="flex-grow"></div>
        {typeof badge === 'number' && badge > 0 && (
          <div className="flex-shrink-0 px-1.5 py-0.5 text-xs font-medium rounded-full bg-primary-foreground/20 text-primary-foreground">
            {badge}
          </div>
        )}


        <Button
          variant="ghost"
          size="icon"
          className="flex-shrink-0 h-6 w-6 text-primary-foreground hover:bg-primary-foreground/10 hover:text-primary-foreground"
          onClick={(e) => {
            e.stopPropagation();
            onChevronClick();
          }}
        >
          {!isOpen ? <ChevronRight className="h-4 w-4" /> : <ChevronLeft className="h-4 w-4" />}
        </Button>
      </div>

      {/* Panel Content */}
      <div className={`bg-primary transition-[max-height] duration-200 ease-in-out overflow-hidden
       
        ${!isOpen ? 'max-h-0' : 'max-h-[calc(100vh-12rem)] md:max-h-[calc(100vh-8rem)]'}`}>
        {children}
      </div>
    </div>
  )
} 