import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PhoneCall, Users, Divide, Loader2, ChevronDown, ChevronUp, ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MillionairesData } from '@superlekcja/shared';
import { Link } from 'react-router-dom';

interface PrizeTier {
  amount: number;
  isGuaranteed: boolean;
}

interface Question {
  question: string;
  answers: Array<{
    text: string;
    isCorrect: boolean;
  }>;
 // difficulty: number;
  hostComment?: string;
  prize: PrizeTier;
}

// Add new interface for randomized answer
interface RandomizedAnswer {
  text: string;
  isCorrect: boolean;
  originalIndex: number;
}

interface MilionerzyGameResponse {
  gameId: string;
  lessonId: string;
  questions: {
    questions: Question[];
    prizeTiers: PrizeTier[];
  };
}

interface MilionerzyGamePageProps {
  title: string;
  item: MillionairesData;
}

type GameState = 'initial' | 'question' | 'confirmation' | 'correct' | 'incorrect';
type Lifeline = '50-50' | 'phone' | 'audience';

interface LifelineState {
  '50-50': boolean;
  phone: boolean;
  audience: boolean;
}

type HostEmotion = 'welcome' | 'neutral' | 'sneaky' | 'excited' | 'sad';

export function MilionerzyGamePage({title, item}: MilionerzyGamePageProps) {
  const { lessonId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasStarted, setHasStarted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [prizeTiers, setPrizeTiers] = useState<PrizeTier[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [gameState, setGameState] = useState<GameState>('initial');
  const [hostComment, setHostComment] = useState<string>('');
  const [randomizedAnswers, setRandomizedAnswers] = useState<RandomizedAnswer[]>([]);
  const [lifelinesUsed, setLifelinesUsed] = useState<LifelineState>({
    '50-50': false,
    phone: false,
    audience: false,
  });
  const [hiddenAnswers, setHiddenAnswers] = useState<number[]>([]);
  const [hostEmotion, setHostEmotion] = useState<HostEmotion>('welcome');
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);

  const currentQuestion = questions[currentQuestionIndex];

  const getRandomEmotion = (): HostEmotion => {
    const emotions: HostEmotion[] = ['neutral', 'sneaky', 'excited'];
    return emotions[Math.floor(Math.random() * emotions.length)];
  };

  const handleStart = async () => {
    setIsLoading(true);
    setError(null);
    try {
      setPrizeTiers(item.questions.map(question => question.prize));
      setQuestions(item.questions);
      setHasStarted(true);
      setGameState('question');
      setCurrentQuestionIndex(0);
      setSelectedAnswer(null);
      setHostEmotion('welcome');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Wystąpił błąd');
      console.error('Error loading game:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const shuffleAnswers = (question: Question) => {
    const answers = question.answers.map((answer, index) => ({
      ...answer,
      originalIndex: index
    }));
    
    // Fisher-Yates shuffle algorithm
    for (let i = answers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [answers[i], answers[j]] = [answers[j], answers[i]];
    }
    
    setRandomizedAnswers(answers);
  };

  useEffect(() => {
    if (currentQuestion && gameState === 'question') {
      shuffleAnswers(currentQuestion);
    }
  }, [currentQuestion, gameState]);

  const handleAnswerSelect = (index: number) => {
    if (gameState === 'question') {
      setSelectedAnswer(randomizedAnswers[index].originalIndex);
      setGameState('confirmation');
    }
  };

  const handleAnswerConfirm = () => {
    if (!currentQuestion || selectedAnswer === null) return;

    const isCorrect = currentQuestion.answers[selectedAnswer].isCorrect;
    setGameState(isCorrect ? 'correct' : 'incorrect');
    setHostEmotion(isCorrect ? 'excited' : 'sad');

    if (isCorrect) {
      setTimeout(() => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(prev => prev + 1);
          setSelectedAnswer(null);
          setGameState('question');
          setHiddenAnswers([]);
          setHostEmotion(getRandomEmotion());
        } else {
          setGameState('correct');
          setHostEmotion('excited');
        }
      }, 2000);
    }
  };

  const handleAnswerChange = () => {
    setGameState('question');
    setSelectedAnswer(null);
  };

  const handleRestartGame = () => {
    setGameState('initial');
    setHasStarted(false);
    setCurrentQuestionIndex(0);
    setSelectedAnswer(null);
    setPrizeTiers([]);
    setQuestions([]);
    setHiddenAnswers([]);
    setRandomizedAnswers([]);
    setLifelinesUsed({
      '50-50': false,
      phone: false,
      audience: false,
    });
    setHostEmotion('welcome');
  };

  const getCurrentPrize = () => {
    if (currentQuestionIndex === 0) return 0;
    
    // Find the last guaranteed amount before current question
    for (let i = currentQuestionIndex - 1; i >= 0; i--) {
      if (prizeTiers[i].isGuaranteed) {
        return prizeTiers[i].amount;
      }
    }
    return 0;
  };

  const getHostComment = () => {
    console.log('Current question:', currentQuestion);
    console.log('Current game state:', gameState);

    if (!currentQuestion) {
      console.log('No question available');
      return '';
    }

    switch (gameState) {
      case 'question':
        return currentQuestion.hostComment || '';
      case 'confirmation':
        if (selectedAnswer === null) return '';
        return `Czy jest to Twoja ostateczna odpowiedź? ${currentQuestion.answers[selectedAnswer].text}?`;
      case 'correct':
        if (currentQuestionIndex === questions.length - 1) {
          return "GRATULACJE! Wygrywasz MILION ZŁOTYCH!";
        }
        return "To jest prawidłowa odpowiedź! Gratulacje!";
      case 'incorrect':
        const prize = getCurrentPrize();
        return `Niestety, to nie jest prawidłowa odpowiedź. Wygrywasz ${prize > 0 ? `${prize.toLocaleString()} złotych` : '0 złotych'}`;
      default:
        return '';
    }
  };

  // Update hostComment whenever gameState or currentQuestion changes
  useEffect(() => {
    const comment = getHostComment();
    if (comment !== hostComment) {
      setHostComment(comment);
    }
  }, [gameState, currentQuestion, selectedAnswer]);

  const handleLifelineUse = (lifeline: Lifeline) => {
    if (lifelinesUsed[lifeline]) return;

    setLifelinesUsed(prev => ({ ...prev, [lifeline]: true }));

    if (lifeline === '50-50' && currentQuestion) {
      // Get indices of incorrect answers from randomized array
      const incorrectAnswers = randomizedAnswers
        .map((answer, index) => ({ index, isCorrect: answer.isCorrect }))
        .filter(answer => !answer.isCorrect)
        .map(answer => answer.index);

      // Randomly select two incorrect answers to hide
      const shuffled = incorrectAnswers.sort(() => Math.random() - 0.5);
      const toHide = shuffled.slice(0, 2);
      setHiddenAnswers(toHide);
    }
  };

  const LifelineButton = ({ type, icon: Icon, label }: { type: Lifeline; icon: any; label: string }) => {
    const isUsed = lifelinesUsed[type];
    
    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button 
            variant="outline" 
            className={cn(
              "flex-1 h-24 flex flex-col items-center justify-center gap-2 p-2 text-center",
              isUsed && "opacity-50"
            )} 
            disabled={isUsed || !hasStarted || gameState !== 'question'}
          >
            <Icon className="w-6 h-6 shrink-0" />
            <span className="text-xs text-muted-foreground whitespace-normal leading-tight">{label}</span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Użyj koła ratunkowego</DialogTitle>
            <DialogDescription>
              Czy na pewno chcesz użyć koła ratunkowego {label}? 
              {type === '50-50' && " Zostaną usunięte dwie błędne odpowiedzi."}
              {type === 'phone' && " Będziesz mógł zapytać nerda o pomoc."}
              {type === 'audience' && " Klasa pomoże Ci w odpowiedzi."}
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-end gap-4 mt-4">
            <Button variant="outline" onClick={() => document.querySelector('dialog')?.close()}>
              Anuluj
            </Button>
            <Button onClick={() => {
              handleLifelineUse(type);
              document.querySelector('dialog')?.close();
            }}>
              Użyj
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="container mx-auto p-4 min-h-[calc(100vh-4rem)] flex flex-col lg:flex-row gap-4">
      {/* Główny obszar gry */}


      
      <div className="flex-1 bg-card rounded-lg shadow-lg p-6 flex flex-col">
        {error ? (
          <div className="text-center flex flex-col items-center justify-center h-full">
            <p className="text-destructive mb-4">{error}</p>
            <Button 
              onClick={handleStart} 
              variant="outline"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Ładowanie...
                </>
              ) : (
                'Spróbuj ponownie'
              )}
            </Button>
          </div>
        ) : !hasStarted ? (
          <div className="text-center flex flex-col items-center justify-center h-full">
            <div className="w-full flex items-center gap-2 mb-6">
              <Link to={`/lessons/${lessonId}`} className="hover:text-primary">
                <ArrowLeft className="w-4 h-4 text-muted-foreground" />
              </Link>
            </div>
            <div className="text-center flex flex-col items-center justify-center h-full">
            <img 
              src="/milionerzy/host_welcome.jpg" 
              alt="Host wita graczy" 
              className="w-48 h-48 object-cover rounded-full mb-6"
            />
            <h1 className="text-2xl font-bold mb-2">Kujonerzy</h1>
            <p className="text-muted-foreground mb-6">
              {title}
            </p>
            <Button
              onClick={handleStart}
              size="lg"
              className="font-semibold"
              data-testid="start-game-button"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Przygotowuję pytania...
                </>
              ) : (
                'Rozpocznij grę'
              )}
            </Button>
            </div>
          </div>
        ) : isLoading ? (
          <div className="text-center flex flex-col items-center justify-center h-full">
            <Loader2 className="h-12 w-12 animate-spin text-primary mb-4" />
            <p className="text-muted-foreground">Ładowanie...</p>
          </div>
        ) : currentQuestion ? (
          <div className="flex flex-col h-full">
                        <div className="w-full flex items-center gap-2 mb-6">
              <Link to={`/lessons/${lessonId}`} className="hover:text-primary">
                <ArrowLeft className="w-4 h-4 text-muted-foreground" />
              </Link>
            </div>
            {/* Host section - centered */}
            <div className="flex-1 flex flex-col items-center justify-center mb-8">
              {/* Host image */}
              <div className="flex justify-center">
                <img 
                  src={`/milionerzy/host_${hostEmotion}.jpg`}
                  alt={`Host - ${hostEmotion}`}
                  className="w-48 h-48 object-cover rounded-full"
                />
              </div>

              {/* Host comment - fixed height */}
              <div className={cn(
                "mt-6 text-center transition-all duration-500 min-h-[100px] flex items-center justify-center px-4 max-w-2xl",
                (gameState === 'correct' || gameState === 'incorrect') ? "text-2xl font-semibold" : "italic text-muted-foreground"
              )}>
                {hostComment}
              </div>
            </div>

            {/* Game section - fixed at bottom */}
            <div className={cn(
              "mt-auto transition-opacity duration-300",
              (gameState === 'incorrect') && "opacity-50"
            )}>
              {/* Question */}
              <div className="mb-6">
                <Card className={cn(
                  "p-6 text-center text-lg font-medium transition-colors duration-300 whitespace-pre-wrap break-words",
                  gameState === 'correct' ? "bg-green-600 text-white" : 
                  gameState === 'incorrect' ? "bg-red-600 text-white" :
                  "bg-primary text-primary-foreground"
                )}>
                  {currentQuestion.question}
                </Card>
              </div>

              {/* Answers */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                {randomizedAnswers.map((answer, index) => (
                  <Button
                    key={index}
                    variant={selectedAnswer === answer.originalIndex ? "default" : "outline"}
                    className={cn(
                      "h-auto min-h-[4rem] py-3 text-left relative overflow-hidden transition-colors duration-300 whitespace-pre-wrap break-words",
                      selectedAnswer === answer.originalIndex && gameState !== 'incorrect' && "bg-primary text-primary-foreground",
                      ((gameState === 'correct' && answer.originalIndex === selectedAnswer) || (gameState === 'incorrect' && answer.isCorrect)) && "bg-green-600 text-white",
                      gameState === 'incorrect' && answer.originalIndex === selectedAnswer && !answer.isCorrect && "bg-red-600 text-white",
                      hiddenAnswers.includes(index) && "opacity-0 pointer-events-none"
                    )}
                    onClick={() => handleAnswerSelect(index)}
                    disabled={gameState !== 'question'}
                  >
                    <span className="absolute left-2 top-3">
                      {String.fromCharCode(65 + index)}.
                    </span>
                    <span className="ml-8 block">{answer.text}</span>
                  </Button>
                ))}
              </div>

              {/* Action buttons container - always present to reserve space */}
              <div className="h-10 flex justify-center gap-4 items-center">
                {gameState === 'confirmation' && (
                  <>
                    <Button
                      variant="outline"
                      size="lg"
                      onClick={handleAnswerChange}
                    >
                      Zmień odpowiedź
                    </Button>
                    <Button
                      variant="default"
                      size="lg"
                      onClick={handleAnswerConfirm}
                      className="w-64"
                    >
                      Zatwierdź odpowiedź
                    </Button>
                  </>
                )}
                {(gameState === 'incorrect' || (gameState === 'correct' && currentQuestionIndex === questions.length - 1)) && (
                  <Button
                    variant="default"
                    size="lg"
                    onClick={handleRestartGame}
                    className="w-64"
                  >
                    Zagraj jeszcze raz
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* Panel z nagrodami i kołami ratunkowymi */}
      <div className="lg:w-80 bg-card rounded-lg shadow-lg">
        {/* Przycisk rozwijania/zwijania na wąskich ekranach */}
        <button 
          className="w-full p-4 flex items-center justify-between lg:hidden border-b border-primary-foreground/20"
          onClick={() => setIsPanelExpanded(!isPanelExpanded)}
        >
          <span className="font-semibold">Koła ratunkowe i nagrody</span>
          {isPanelExpanded ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </button>

        {/* Zawartość panelu - widoczna zawsze na szerokich ekranach lub po rozwinięciu na wąskich */}
        <div className={cn(
          "overflow-hidden transition-all duration-300",
          isPanelExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0 lg:max-h-none lg:opacity-100"
        )}>
          <div className="p-4">
            <div className="mb-6">
              <h2 className="text-lg font-semibold mb-3">Koła ratunkowe</h2>
              <div className="grid grid-cols-3 gap-2">
                <LifelineButton type="50-50" icon={Divide} label="Wykreślanka" />
                <LifelineButton type="phone" icon={PhoneCall} label="Pytaj nerda" />
                <LifelineButton type="audience" icon={Users} label="Co mówi klasa" />
              </div>
            </div>

            <div>
              <h2 className="text-lg font-semibold mb-2">Nagrody</h2>
              <div className="space-y-1.5">
                {prizeTiers.length > 0 ? (
                  [...prizeTiers].reverse().map((prize, index) => {
                    const actualIndex = prizeTiers.length - 1 - index;
                    const isCurrentQuestion = actualIndex === currentQuestionIndex;
                    const isPassed = actualIndex < currentQuestionIndex;

                    return (
                      <div
                        key={prize.amount}
                        className={cn(
                          "py-1 px-2 rounded flex items-center justify-between",
                          isCurrentQuestion && "bg-primary text-primary-foreground",
                          isPassed && "text-muted-foreground",
                          prize.isGuaranteed && "font-semibold"
                        )}
                      >
                        <span>{actualIndex + 1}.</span>
                        <span className="flex-1 text-right">
                          {prize.amount.toLocaleString()} zł
                          {prize.isGuaranteed && " (gwarantowane)"}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center text-muted-foreground">
                    Rozpocznij grę, aby zobaczyć nagrody
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 