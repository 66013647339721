import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AppBar } from './AppBar'
import { Footer } from '../Footer'

export function Layout() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <div className="min-h-screen flex flex-col print:block print:min-h-0 bg-gradient-to-br from-[#97a5c5] to-[#e5edfd] print:bg-none">
      <AppBar />
      <main className="flex-1 relative print:static mt-16 print:mt-0">
        <div className="absolute inset-0 print:static print:min-h-screen overflow-y-auto">
          <Outlet />
        </div>
      </main>
      {isHomePage && <Footer />}
    </div>
  )
} 