import { Link } from "react-router-dom"
import { useState } from "react"
import { CookiePreferencesDialog } from "./CookiePreferencesDialog"

export function Footer() {
  const [showCookiePreferences, setShowCookiePreferences] = useState(false)

  return (
    <>
      <footer className="sticky bottom-0 w-full backdrop-blur-sm py-2 print:hidden">
        <div className="container mx-auto px-4">
          <div className="flex justify-center items-center gap-4 text-sm text-foreground/70">
            <Link to="/privacy" className="hover:text-foreground transition-colors">
              Polityka prywatności
            </Link>
            <span>•</span>
            <Link to="/terms" className="hover:text-foreground transition-colors">
              Regulamin
            </Link>
            <span>•</span>
            <button 
              onClick={() => setShowCookiePreferences(true)}
              className="hover:text-foreground transition-colors"
            >
              Cookies
            </button>
          </div>
        </div>
      </footer>

      <CookiePreferencesDialog 
        isOpen={showCookiePreferences}
        onClose={() => setShowCookiePreferences(false)}
      />
    </>
  )
} 