import { ProblemSolvingData } from '@superlekcja/shared';
import { MarkdownView } from '../MarkdownView';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { SimpleMarkdownView } from '../SimpleMarkdownView';

interface ProblemSolvingProps {
  data: ProblemSolvingData;
  showSolution?: boolean;
}

export function ProblemSolving({ data, showSolution = false }: ProblemSolvingProps) {
  return (
    <div className="space-y-4">
      
        <SimpleMarkdownView content={data.problem} />
      
      {showSolution && (
        <SimpleMarkdownView content={data.answer} />
      )}
    </div>
  );
} 