import { useState } from 'react'
import { Button } from './ui/button'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'
import { Pencil, Trash2, Sparkles, Lightbulb, RefreshCw, XCircle } from 'lucide-react'
import type { AlterationDTO } from '@superlekcja/shared'

interface HeaderActionButtonProps {
  title: string
  level: 1 | 2 | 3
  onAction: (modification: AlterationDTO | null) => void
  activeModifications?: AlterationDTO[]
}

const ACTION_ICONS = {
  remove: Trash2,
  enhance: Sparkles,
  simplify: Lightbulb,
  modify: RefreshCw,
  nochange: XCircle
}

export function HeaderActionButton({ 
  title, 
  level,
  onAction,
  activeModifications = []
}: HeaderActionButtonProps) {
  const [open, setOpen] = useState(false)

  const handleAction = (action: AlterationDTO['action'] | 'nochange') => {
    setOpen(false)
    if (action === 'nochange') {
      onAction(null) // Pass null to indicate removal of modifications
    } else {
      onAction({
        action,
        text: title,
        level
      })
    }
  }

  // Get active modifications for this section
  const sectionModifications = activeModifications.filter(
    mod => mod.text === title && mod.level === level
  )

  const hasModifications = sectionModifications.length > 0

  return (
    <div className="flex items-center gap-1">
      {sectionModifications.map((mod, index) => {
        const Icon = ACTION_ICONS[mod.action]
        return (
          <div 
            key={`${mod.action}-${index}`}
            className="p-1 rounded bg-gray-100 text-gray-600"
            title={`${mod.action.charAt(0).toUpperCase() + mod.action.slice(1)} this section`}
          >
            <Icon className="h-3 w-3" />
          </div>
        )
      })}
      
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="h-6 w-6 p-0 hover:bg-gray-100"
          >
            <Pencil className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-40 p-1" align="end">
          {hasModifications && (
            <>
              <Button
                variant="ghost"
                size="sm"
                className="w-full justify-start gap-2 text-gray-600 hover:text-gray-600 hover:bg-gray-50"
                onClick={() => handleAction('nochange')}
                data-testid="header-nochange-button"
              >
                <XCircle className="h-4 w-4" />
                Nie zmieniaj
              </Button>
              <div className="h-px bg-gray-100 my-1" />
            </>
          )}
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start gap-2"
            onClick={() => handleAction('enhance')}
            data-testid="header-enhance-button"
          >
            <Sparkles className="h-4 w-4" />
            Rozbuduj
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start gap-2"
            onClick={() => handleAction('simplify')}
            data-testid="header-simplify-button"
          >
            <Lightbulb className="h-4 w-4" />
            Uprość
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start gap-2"
            onClick={() => handleAction('modify')}
            data-testid="header-modify-button"
          >
            <RefreshCw className="h-4 w-4" />
            Zmodyfikuj
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="w-full justify-start gap-2 text-red-600 hover:text-red-600 hover:bg-red-50"
            onClick={() => handleAction('remove')}
            data-testid="header-remove-button"
          >
            <Trash2 className="h-4 w-4" />
            Usuń
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  )
} 