import { createContext, useContext, useEffect, useState, useCallback, ReactNode, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from '../components/ui/use-toast';
import { profileService, Transaction } from '../services/profile.service';
import { useAuth } from './AuthContext';
import { UserProfileDialog } from '@/components/auth/UserProfileDialog';

interface ProfileDialogContextType {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

const ProfileDialogContext = createContext<ProfileDialogContextType | undefined>(undefined);

export function useProfileDialog() {
  const context = useContext(ProfileDialogContext);
  if (!context) {
    throw new Error('useProfileDialog must be used within a ProfileDialogProvider');
  }
  return context;
}

interface ProfileDialogProviderProps {
  children: ReactNode;
}

export function ProfileDialogProvider({ children }: ProfileDialogProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTransaction, setCurrentTransaction] = useState<Transaction | null>(null);
  const { toast } = useToast();
  const { refreshProfile } = useAuth();
  const timerRef = useRef<number | null>(null);

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => setIsOpen(false), []);

  const stopPolling = useCallback(() => {
    if (timerRef.current !== null) {
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  // Stop polling when dialog is closed
  useEffect(() => {
    if (!isOpen) {
      stopPolling();
    }
  }, [isOpen, stopPolling]);

  // Clean up polling on unmount
  useEffect(() => {
    return stopPolling;
  }, [stopPolling]);

  const pollTransaction = useCallback(async (transactionId: string) => {
    try {
      const transaction = await profileService.getTransactionByExtId(transactionId);
      setCurrentTransaction(transaction);

      if (transaction.status !== 'pending') {
        // Stop polling
        stopPolling();

        // Remove query parameter
        searchParams.delete('transactionPending');
        setSearchParams(searchParams);

        // Refresh profile to get updated credits
        await refreshProfile();

        // Show status message
        if (transaction.status === 'completed') {
          toast({
            title: "Płatność zakończona",
            description: `Dodano ${transaction.credits_amount} kredytów do Twojego konta.`,
          });
        } else if (transaction.status === 'failed') {
          toast({
            variant: "destructive",
            title: "Błąd płatności",
            description: "Płatność nie została zrealizowana. Spróbuj ponownie później.",
          });
        }
        return false; // Signal to stop polling
      }
      return true; // Signal to continue polling
    } catch (error) {
      console.error('Failed to check transaction status:', error);
      toast({
        variant: "destructive",
        title: "Błąd",
        description: "Nie udało się sprawdzić statusu płatności. Spróbuj ponownie później.",
      });
      stopPolling();
      return false; // Signal to stop polling
    }
  }, [searchParams, setSearchParams, refreshProfile, toast, stopPolling]);

  // Handle transaction pending parameter
  useEffect(() => {
    const transactionId = searchParams.get('transactionPending');
    if (!transactionId) return;

    // Open dialog
    openDialog();

    // Function to schedule next poll
    const scheduleNextPoll = async () => {
      const shouldContinue = await pollTransaction(transactionId);
      if (shouldContinue) {
        stopPolling();
        timerRef.current = window.setTimeout(scheduleNextPoll, 3000);
      }
    };

    
    stopPolling();
    timerRef.current = window.setTimeout(scheduleNextPoll, 500);

    // Cleanup function
    return () => {
      stopPolling();
    };
  }, [searchParams, openDialog, pollTransaction, stopPolling]);

  const handleLogout = () => {
    navigate('/')
  }

  return (
    <ProfileDialogContext.Provider value={{ isOpen, openDialog, closeDialog }}>
      <UserProfileDialog 
        isOpen={isOpen}
        onClose={closeDialog}
        onLogout={handleLogout}
      />
      {children}
    </ProfileDialogContext.Provider>
  );
} 