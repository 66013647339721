export const samplePrompts = [
  'Jak działa pieniądz i dlaczego warto oszczędzać?',
  'Wprowadzenie do równań kwadratowych dla liceum',
  'Dlaczego niebo jest niebieskie? Lekcja dla 8-latków',
  'Jak powstają burze i pioruny? Lekcja dla dzieci',
  'Jak dbać o środowisko - segregacja śmieci i recykling',
  'Jak działa internet i dlaczego jest taki ważny?',
  'Skąd się bierze deszcz? Lekcja dla przedszkolaków',
  'Angielski: jak zamawiać jedzenie w restauracji',
  'Dlaczego dinozaury wyginęły? Lekcja dla dzieci',
  'Jak działa ludzki mózg? Lekcja dla 12-latków',
  'Jak powstaje tęcza? Lekcja dla najmłodszych',
  'Jak bezpiecznie korzystać z internetu?',
  'Dlaczego musimy myć zęby? Lekcja o higienie',
  'Jak działa GPS i dlaczego nie gubimy się w mieście?',
  'Skąd się bierze prąd w gniazdku?',
  'Lekcja o fotosyntezie dla klasy 7',
  'Wprowadzenie do równań kwadratowych dla liceum',
  'Omów lekturę "Pan Tadeusz"',
  'Lekcja o II Wojnie Światowej dla klasy 8',
  'Wprowadzenie do programowania w Pythonie dla liceum',
  'Budowa i funkcje komórki dla gimnazjum',
  'Zasady ortografii - pisownia "rz" i "ż"',
  'Angielski: Present Perfect vs Past Simple',
  'Reakcje utleniania i redukcji',
  'Klimaty kuli ziemskiej',
  'Budowa atomu',
  'Podstawy teorii względności'
]; 