import { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { GenerationStatus } from '../components/GenerationStatus'
import { createScenario, generateWorksheetItem, removeWorksheetItem, getLesson, createKnowledge, StreamCallbacks, copyLesson, navigateWorksheetVersion, navigateScenarioVersion, navigateKnowledgeVersion, generateImagesForScenario } from '../services/lesson.service'
import type { LessonDTO, KnowledgeDTO, ScenarioDTO, WorksheetItemType, ScenarioGenerationSettingsDTO, AlterationDTO, KnowledgeGenerationSettingsDTO } from '@superlekcja/shared'
import { ScenarioView } from '../components/ScenarioView'
import { WorksheetView } from '../components/WorksheetView'
import { useProfileDialog } from '../contexts/ProfileDialogProvider'
import { useToast } from '../components/ui/use-toast'
import { Button } from '../components/ui/button'
import { Loader2, Printer, AlertCircle, ScrollText, BookOpen, Dumbbell } from 'lucide-react'
import { useAuth } from '../contexts/AuthContext'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"
import { streamContent } from '../services/lesson.service'
import { KnowledgeView } from '../components/KnowledgeView'
import { INSUFFICIENT_CREDITS } from '@/services/api'
import { ControlPanel } from '../components/ui/control-panel'
import { ScenarioPanelContent } from '../components/ScenarioPanelContent'
import { KnowledgePanelContent } from '../components/KnowledgePanelContent'
import { WorksheetPanelContent } from '../components/WorksheetPanelContent'
import { AuthModal } from '../components/auth/AuthModal'
import { 
  trackRegenerateScenario,
  trackRegenerateKnowledge,
  trackGenerateWorksheetItem,
  trackRegenerateWorksheetItem
} from '../utils/analytics'
import { ImageDialog } from '../components/ImageDialog'

export function LessonPage() {
  const { lessonId } = useParams<{ lessonId: string }>()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { toast } = useToast()
  const { openDialog : showProfileDialog } = useProfileDialog()
  const queryClient = useQueryClient()
  const [showAuthModal, setShowAuthModal] = useState(false)

  // Load lesson with React Query
  const { 
    data: lesson,
    isLoading: isLessonLoading,
    error: lessonError
  } = useQuery<LessonDTO>({
    queryKey: ['lesson', lessonId],
    queryFn: async () => {
      if (!lessonId) throw new Error('No lesson ID provided');
      return getLesson(lessonId);
    },
    enabled: !!lessonId,
    staleTime: 30 * 1000, // Consider data fresh for 30 seconds
    gcTime: 5 * 60 * 1000, // Keep in cache for 5 minutes (formerly cacheTime)
    retry: 2,
  });

  // Helper function to update lesson in cache
  const updateLesson = (updater: (oldLesson: LessonDTO) => LessonDTO) => {
    if (!lesson || !lessonId) return;
    queryClient.setQueryData(['lesson', lessonId], updater);
  };

  const [isLessonEditable, setIsLessonEditable] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const [activePanel, setActivePanel] = useState<'scenario' | 'worksheet' | 'knowledge'>('scenario')
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024)
  const [isPanelExpanded, setIsPanelExpanded] = useState(isLargeScreen)
  const abortControllerRef = useRef<AbortController | null>(null)
  const [autoScroll, setAutoScroll] = useState(true)
  const autoScrollRef = useRef(autoScroll)

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Handle automatic panel expansion on large screens
  useEffect(() => {
    if (isLargeScreen) {
      setIsPanelExpanded(true)
    }
  }, [activePanel, isLargeScreen])

  // scenario
  const [scenarioContent, setScenarioContent] = useState('')
  const [generatingScenario, setGeneratingScenario] = useState(false)
  const [generatingScenarioProgress, setGeneratingScenarioProgress] = useState<string[]>([])
  const [scenarioSettings, setScenarioSettings] = useState<ScenarioGenerationSettingsDTO>({
    duration: 45,
    difficulty: 2
  })
  const [originalScenarioSettings, setOriginalScenarioSettings] = useState<ScenarioGenerationSettingsDTO | null>(null)
  const [scenarioAlterations, setScenarioAlterations] = useState<AlterationDTO[]>([])

  // knowledge
  const [knowledgeContent, setKnowledgeContent] = useState('')
  const [generatingKnowledge, setGeneratingKnowledge] = useState(false)
  const [generatingKnowledgeProgress, setGeneratingKnowledgeProgress] = useState<string[]>([])
  const [knowledgeSettings, setKnowledgeSettings] = useState<KnowledgeGenerationSettingsDTO>({difficulty: 2});
  const [originalKnowledgeSettings, setOriginalKnowledgeSettings] = useState<KnowledgeGenerationSettingsDTO | null>(null);
  const [knowledgeAlternations, setKnowledgeAlternations] = useState<AlterationDTO[]>([]);

  // worksheet
  const [isGeneratingWorksheetItem, setIsGeneratingWorksheetItem] = useState<{ type: WorksheetItemType, id?: string } | null>(null)
  const [showWorksheetSolutions, setShowWorksheetSolutions] = useState(false);

  // Add state for tracking undo/redo capability
  const canUndoScenario = (() => {
    if (!lesson?.scenario?.id || !lesson?.scenarioHistory) return false;
    return lesson.scenarioHistory.indexOf(lesson.scenario.id) > 0;
  })()

  const canRedoScenario = (() => {
    if (!lesson?.scenario?.id || !lesson?.scenarioHistory) return false;
    return lesson.scenarioHistory.indexOf(lesson.scenario.id) < lesson.scenarioHistory.length - 1;
  })()

  const canUndoWorksheet = (() => {
    if (!lesson?.worksheet?.id || !lesson?.worksheetHistory) return false;
    return lesson.worksheetHistory.indexOf(lesson.worksheet.id) > 0;
  })()

  const canRedoWorksheet = (() => {
    if (!lesson?.worksheet?.id || !lesson?.worksheetHistory) return false;
    return lesson.worksheetHistory.indexOf(lesson.worksheet.id) < lesson.worksheetHistory.length - 1;
  })()

  // Add state for tracking knowledge undo/redo capability
  const canUndoKnowledge = (() => {
    if (!lesson?.knowledge?.id || !lesson?.knowledgeHistory) return false;
    return lesson.knowledgeHistory.indexOf(lesson.knowledge.id) > 0;
  })()

  const canRedoKnowledge = (() => {
    if (!lesson?.knowledge?.id || !lesson?.knowledgeHistory) return false;
    return lesson.knowledgeHistory.indexOf(lesson.knowledge.id) < lesson.knowledgeHistory.length - 1;
  })()

  const hasKnowledgeChanges = (() => {
    if (!lesson?.knowledge) return false;
    const difficultyChanged = knowledgeSettings?.difficulty !== originalKnowledgeSettings?.difficulty;
    return difficultyChanged || knowledgeAlternations.length > 0;
  })()

  const scrollToBottom = () => {
    if (autoScrollRef.current) {
      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      }, 10)
    }
  }

  const scrollToTop = () => {
    if (autoScrollRef.current) {
      setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }},10)
    }
  }

  const applyAutoScroll = (enabled: boolean) => {
    setAutoScroll(enabled)
    autoScrollRef.current = enabled
    if (enabled) scrollToBottom();
  }

  // Update lesson editable state when lesson or user changes
  useEffect(() => {
    if (lesson && user) {
      setIsLessonEditable(lesson.userId === user.id);
    }
  }, [lesson, user]);

  // Handle streaming content when lesson status changes
  useEffect(() => {
    if (lesson?.scenario.status === 'new' || lesson?.scenario.status === 'generating') {
      streamContent(lesson.id, 'scenario', streamScenarioCallbacks)
        .finally(()=>setGeneratingScenario(false));
    }
    if (lesson?.knowledge?.status === 'new' || lesson?.knowledge?.status === 'generating') {
      streamContent(lesson.id, 'knowledge', knowledgeStreamCallbacks)
        .finally(()=>setGeneratingKnowledge(false));
    }
  }, [lesson?.id, lesson?.scenario.status, lesson?.knowledge?.status]);

  const streamScenarioCallbacks: StreamCallbacks<ScenarioDTO> = {
    onStart: () => {
      setScenarioContent(() => (''))
      setGeneratingScenario(true)
      setGeneratingScenarioProgress([])
    },
    onProgress: (progress) => {
      // TODO: display progress in UI
      setGeneratingScenarioProgress(prev => [...prev, progress])
    },
    onContent: (chunk) => {
      setScenarioContent(prev => prev + chunk)
      scrollToBottom()
    },
    onComplete: (scenario: ScenarioDTO) => {
      updateLesson(prev => ({ ...prev, scenario }));
      setGeneratingScenario(false)
      scrollToTop()
    },
    onError: (error) => {
      handleError(error, "Wystąpił błąd podczas generowania scenariusza.")
      setGeneratingScenario(false)
    }
  }

  const knowledgeStreamCallbacks: StreamCallbacks<KnowledgeDTO> = {
    onStart: () => {
      setKnowledgeContent(() => (''))
      setGeneratingKnowledge(true)
      setGeneratingKnowledgeProgress([])
    },
    onProgress: (progress) => {
      // TODO: display progress in UI
      setGeneratingKnowledgeProgress(prev => [...prev, progress])
    },
    onContent: (chunk) => {
      setKnowledgeContent(prev => prev + chunk)
      scrollToBottom()
    },
    onComplete: (knowledge: KnowledgeDTO) => {
      updateLesson(prev => ({ ...prev, knowledge }));
      setGeneratingKnowledge(false)
      scrollToTop()
    },
    onError: (error) => {
      handleError(error, "Wystąpił błąd podczas generowania kompendium.")
      setGeneratingKnowledge(false)
    }
  }

  // populate related fields when lesson changes
  useEffect(() => {
    if (lesson?.scenario.prompt) {
      setOriginalScenarioSettings({ ...lesson.scenario.prompt.settings })
      setScenarioSettings({ ...lesson.scenario.prompt.settings })
      setScenarioAlterations([]) // Reset modifications when scenario changes
    }

    if (lesson?.knowledge?.prompt) {
      setOriginalKnowledgeSettings({ ...lesson.knowledge.prompt.settings })
      setKnowledgeSettings({ ...lesson.knowledge.prompt.settings })
      setKnowledgeAlternations([]) // Reset modifications when knowledge changes
    }

    if (lesson?.scenario.status === 'completed') {
      setScenarioContent(lesson.scenario.content?.content || '')
      setKnowledgeContent(lesson.knowledge?.content?.content || '')
    }
  }, [lesson])

  const hasSettingsChanged = () => {
    if (!originalScenarioSettings) return false;
    return scenarioSettings.duration !== originalScenarioSettings.duration ||
      scenarioSettings.difficulty !== originalScenarioSettings.difficulty;
  }

  const handleAbort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
      setGeneratingScenario(false)
    }
  }

  const handleError = (error: unknown, description:string) => {
    const errorMessage = error instanceof Error ? error.message : 'An error occurred'
    if (errorMessage === INSUFFICIENT_CREDITS) {
      showProfileDialog()
      toast({
        variant: "destructive",
        title: "Brak kredytów",
        description: "Kup więcej kredytów aby kontynuować generowanie scenariuszy.",
      })
    } else {
      toast({
        
        variant: "destructive",
        title: "Błąd",
        description
      })      
    }
  }

  const regenerateScenario = async (options: {
    settings: ScenarioGenerationSettingsDTO;
    alterations?: AlterationDTO[];
  }) => {
    if (!lesson) return;
    const { settings, alterations } = options
    setGeneratingScenario(true)
    setGeneratingScenarioProgress([])
    try {
      trackRegenerateScenario({
        difficulty: settings.difficulty,
        duration: settings.duration,
        hasAlterations: !!alterations?.length,
        alterationsCount: alterations?.length || 0,
        success: true
      });
      
      const { item: scenario, history: scenarioHistory } = await createScenario(lesson.id, {
        prompt: {
          settings,
          alterations
        }
      })
      updateLesson(prev => ({ ...prev, scenario, scenarioHistory }));
    } catch (error) {
      handleError(error, "Wystąpił błąd podczas generowania scenariusza.")
    } finally {
      setGeneratingScenario(false)
    }
  }

  const regenerateKnowledge = async (options: {
    settings?: KnowledgeGenerationSettingsDTO;
    alterations?: AlterationDTO[];
  }) => {
    if (!lesson) return;
    const { alterations } = options;
    
    const difficulty = knowledgeSettings?.difficulty ?? lesson.scenario.prompt.settings.difficulty;
    
    setGeneratingKnowledge(true);
    try {
      trackRegenerateKnowledge({
        difficulty,
        hasAlterations: !!alterations?.length,
        alterationsCount: alterations?.length || 0,
        success: true
      });

      const { item: knowledge, history: knowledgeHistory } = await createKnowledge(lesson.id, {
        prompt: {
          settings: knowledgeSettings ?? { difficulty: 2 },
          alterations: knowledgeAlternations
        }
      });

      updateLesson(prev => ({ ...prev, knowledge, knowledgeHistory }));
    } catch (error) {
      handleError(error, "Wystąpił błąd podczas generowania kompendium.");
    } finally {
      setGeneratingKnowledge(false);
    }
  };

  const handlePrint = () => {
    window.print()
  }

  const handleRevertChanges = () => {
    // Reset settings to original values
    if (originalScenarioSettings) {
      setScenarioSettings(originalScenarioSettings)
    }
    // Clear all modifications
    setScenarioAlterations([])
  }

  const handleSectionModification = (modification: AlterationDTO | null) => {
    if (modification === null) {
      // Remove all modifications for this section
      return setScenarioAlterations([])
    }

    // Update modifications state
    setScenarioAlterations(prev => {
      // Remove any existing modifications for this section
      const filtered = prev.filter(
        mod => !(mod.text === modification.text && mod.level === modification.level)
      )
      // Add the new modification
      return [...filtered, modification]
    })
  }

  const handleGenerateWorksheetItem = async (type: WorksheetItemType) => {
    if (!lesson?.scenario.id) return;

    try {
      setIsGeneratingWorksheetItem({ type });
      trackGenerateWorksheetItem({
        worksheetItemType: type,
        difficulty: lesson.scenario.prompt.settings.difficulty,
        success: true
      });
      const { item: worksheet, history: worksheetHistory } = await generateWorksheetItem(lesson.id, type);
      updateLesson(prev => ({ ...prev, worksheet, worksheetHistory }));
    } catch (error) {
      handleError(error, "Wystąpił błąd podczas generowania ćwieczenia.");
    } finally {
      setIsGeneratingWorksheetItem(null);
    }
  };

  const handleRemoveWorksheetItem = async (itemId: string) => {
    if (!lesson?.scenario.id) return;

    try {
      const { item: worksheet, history: worksheetHistory } = await removeWorksheetItem(lesson.id, itemId);
      updateLesson(prev => ({ ...prev, worksheet, worksheetHistory }));
    } catch (error) {
      handleError(error, "Wystąpił błąd podczas usuwania ćwieczenia.");
    }
  };

  const handleRegeneratePuzzle = async (puzzleId: string, type: WorksheetItemType) => {
    if (!lesson?.scenario.id) return;

    try {
      setIsGeneratingWorksheetItem({ type, id: puzzleId });
      trackRegenerateWorksheetItem({
        worksheetItemType: type,
        difficulty: lesson.scenario.prompt.settings.difficulty,
        success: true
      });
      const { item: worksheet, history: worksheetHistory } = await generateWorksheetItem(lesson.id, type, puzzleId);
      updateLesson(prev => ({ ...prev, worksheet, worksheetHistory }));
    } catch (error) {
      let errorMessage = `Wystąpił błąd - spróbuj ponownie.`;
      if (error instanceof Error && error.message === 'No auth token available') {
        errorMessage = `Musisz być zalogowany aby wygenerować ${type === 'riddle' ? 'zagadki' : type}.`;
      }
      toast({
        variant: "destructive",
        title: "Błąd",
        description: errorMessage,
      });
    } finally {
      setIsGeneratingWorksheetItem(null);
    }
  };

  const handleCopy = async () => {
    if (!lesson?.id) return
    if (!user) {
      setShowAuthModal(true)
      return
    }
    try {
      const copiedLesson = await copyLesson(lesson.id)
      navigate(`/lessons/${copiedLesson.id}`)
      toast({
        title: "Sukces",
        description: "Lekcja została skopiowana.",
      })
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Błąd",
        description: "Nie udało się skopiować lekcji.",
      })
    }
  }

  const handleWorksheetUndo = async () => {
    if (!lesson?.worksheet?.previousId || !lesson?.id) return;
    try {
      const { item: worksheet, history: worksheetHistory } = await navigateWorksheetVersion(lesson.id, lesson.worksheet.previousId);
      updateLesson(prev => ({ ...prev, worksheet, worksheetHistory }));
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Błąd",
        description: "Nie udało się cofnąć zmian w karcie pracy.",
      });
    }
  };

  const handleWorksheetRedo = async () => {
    if (!lesson?.worksheet?.id || !lesson?.id || !lesson?.worksheetHistory) return;
    // Find the next version in history
    const currentIndex = lesson.worksheetHistory.indexOf(lesson.worksheet.id);
    if (currentIndex === -1 || currentIndex === lesson.worksheetHistory.length - 1) return;

    const nextVersionId = lesson.worksheetHistory[currentIndex + 1];
    try {
      const { item: worksheet, history: worksheetHistory } = await navigateWorksheetVersion(lesson.id, nextVersionId);
      updateLesson(prev => ({ ...prev, worksheet, worksheetHistory }));
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Błąd",
        description: "Nie udało się przywrócić zmian w karcie pracy.",
      });
    }
  };

  const handleScenarioUndo = async () => {
    if (!lesson?.scenario?.id || !lesson?.scenarioHistory) return;
    const currentIndex = lesson.scenarioHistory.indexOf(lesson.scenario.id);
    if (currentIndex <= 0) return;

    try {
      const { item: scenario, history: scenarioHistory } = await navigateScenarioVersion(
        lesson.id,
        lesson.scenarioHistory[currentIndex - 1]
      );
      updateLesson(prev => ({ ...prev, scenario, scenarioHistory }));
    } catch (error) {
      handleError(error, "Nie udało się wczytać poprzedniej wersji scenariusza.");
    }
  };

  const handleScenarioRedo = async () => {
    if (!lesson?.scenario?.id || !lesson?.scenarioHistory) return;
    const currentIndex = lesson.scenarioHistory.indexOf(lesson.scenario.id);
    if (currentIndex >= lesson.scenarioHistory.length - 1) return;

    try {
      const { item: scenario, history: scenarioHistory } = await navigateScenarioVersion(
        lesson.id,
        lesson.scenarioHistory[currentIndex + 1]
      );
      updateLesson(prev => ({ ...prev, scenario, scenarioHistory }));
    } catch (error) {
      handleError(error, "Nie udało się wczytać następnej wersji scenariusza.");
    }
  };

  const handleKnowledgeUndo = async () => {
    if (!lesson?.knowledge?.id || !lesson?.knowledgeHistory) return;
    const currentIndex = lesson.knowledgeHistory.indexOf(lesson.knowledge.id);
    if (currentIndex <= 0) return;

    try {
      const { item: knowledge, history: knowledgeHistory } = await navigateKnowledgeVersion(
        lesson.id,
        lesson.knowledgeHistory[currentIndex - 1]
      );
      updateLesson(prev => ({ ...prev, knowledge, knowledgeHistory }));
    } catch (error) {
      handleError(error, "Nie udało się wczytać poprzedniej wersji kompendium.");
    }
  };

  const handleKnowledgeRedo = async () => {
    if (!lesson?.knowledge?.id || !lesson?.knowledgeHistory) return;
    const currentIndex = lesson.knowledgeHistory.indexOf(lesson.knowledge.id);
    if (currentIndex >= lesson.knowledgeHistory.length - 1) return;

    try {
      const { item: knowledge, history: knowledgeHistory } = await navigateKnowledgeVersion(
        lesson.id,
        lesson.knowledgeHistory[currentIndex + 1]
      );
      updateLesson(prev => ({ ...prev, knowledge, knowledgeHistory }));
    } catch (error) {
      handleError(error, "Nie udało się wczytać następnej wersji kompendium.");
    }
  };

  const handleKnowledgeModification = (modification: AlterationDTO | null) => {
    if (modification === null) {
      setKnowledgeAlternations([]);
    } else {
      setKnowledgeAlternations(prev => {
        // Remove any existing modifications for this section
        const filtered = prev.filter(
          mod => mod.text !== modification.text || mod.level !== modification.level
        );
        // Add the new modification
        return [...filtered, modification];
      });
    }
  };

  // Add state for image generation
  const [isGeneratingImages, setIsGeneratingImages] = useState(false);
  const [generatedImages, setGeneratedImages] = useState<Array<{ title: string; description: string; url: string; }>>([]);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);

  const handleGenerateImages = async () => {
    if (!lessonId || !lesson?.scenario?.id) return;
    
    setIsGeneratingImages(true);
    try {
      const images = await generateImagesForScenario(lessonId);
      setGeneratedImages(images);
      setIsImageDialogOpen(true);
      toast({
        title: "Wygenerowano obrazy",
        description: `Wygenerowano ${images.length} obrazów dla scenariusza.`,
      });
    } catch (error: any) {
      toast({
        title: "Błąd generowania obrazów",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsGeneratingImages(false);
    }
  };

  // If there's an error from React Query, display it
  if (lessonError) {
    const errorMessage = lessonError instanceof Error ? lessonError.message : 'Failed to load lesson';
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="flex flex-col items-center space-y-4" data-testid="lesson-fetch-error">
          <AlertCircle className="h-12 w-12 text-destructive" />
          <h2 className="text-xl font-semibold">Błąd</h2>
          <p className="text-sm text-muted-foreground">Nie udało się załadować lekcji.</p>
          <p className="text-xs text-muted-foreground">{errorMessage}</p>
        </div>
      </div>
    );
  }

  if (isLessonLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-12 w-12 animate-spin text-primary" />
          <p className="text-sm text-muted-foreground">Wczytywanie lekcji...</p>
        </div>
      </div>
    );
  }

  // For completed scenarios, just display the content
  return (
    <div className="flex h-full print:block print:h-auto">
      {/* Left fixed column - Panels */}
      <div className={`print:hidden absolute flex gap-4 z-10 left-0 top-0
        right-0 h-0 p-4 flex flex-row items-start justify-center
        lg:bottom-0 lg:w-64 lg:h-full lg:p-6 lg:flex-col lg:items-stretch lg:justify-start
      `}>
          <ControlPanel
            title="Scenariusz"
            icon={ScrollText}
            isOpen={activePanel === 'scenario' && isPanelExpanded}
            isActive={activePanel === 'scenario'}
            onToggle={() => {
              if (activePanel === 'scenario') {
                if (!isLargeScreen) {
                  setIsPanelExpanded(!isPanelExpanded);
                }
              } else {
                setActivePanel('scenario');
                if (!isLargeScreen) {
                  setIsPanelExpanded(false);
                }
              }
            }}
            onChevronClick={() => {
              setActivePanel('scenario');
              if (!isLargeScreen) {
                setIsPanelExpanded(activePanel !== 'scenario' || !isPanelExpanded);
              }
            }}
          >
            <ScenarioPanelContent
              settings={scenarioSettings}
              onSettingsChange={setScenarioSettings}
              onRegenerate={() => regenerateScenario({
                settings: scenarioSettings,
                alterations: scenarioAlterations
              })}
              onUndo={handleScenarioUndo}
              onRedo={handleScenarioRedo}
              onRevertChanges={handleRevertChanges}
              onCopy={handleCopy}
              onGenerateImages={handleGenerateImages}
              canUndo={canUndoScenario}
              canRedo={canRedoScenario}
              isGenerating={generatingScenario}
              isGeneratingImages={isGeneratingImages}
              hasChanges={!!hasSettingsChanged() || scenarioAlterations.length > 0}
              isEditable={isLessonEditable}
            />
          </ControlPanel>

          <ControlPanel
            title="Kompendium"
            icon={BookOpen}
            isOpen={activePanel === 'knowledge' && isPanelExpanded}
            isActive={activePanel === 'knowledge'}
            onToggle={() => {
              if (activePanel === 'knowledge') {
                if (!isLargeScreen) {
                  setIsPanelExpanded(!isPanelExpanded);
                }
              } else {
                setActivePanel('knowledge');
                if (!isLargeScreen) {
                  setIsPanelExpanded(false);
                }
              }
            }}
            onChevronClick={() => {
              setActivePanel('knowledge');
              if (!isLargeScreen) {
                setIsPanelExpanded(activePanel !== 'knowledge' || !isPanelExpanded);
              }
            }}
          >
            <KnowledgePanelContent
              onGenerate={() => regenerateKnowledge({
                alterations: knowledgeAlternations
              })}
              isGenerating={generatingKnowledge}
              disabled={generatingScenario}
              isEditable={isLessonEditable}
              onUndo={handleKnowledgeUndo}
              onRedo={handleKnowledgeRedo}
              canUndo={canUndoKnowledge}
              canRedo={canRedoKnowledge}
              knowledge={lesson?.knowledge ?? null}
              settings={knowledgeSettings}
              onSettingsChange={setKnowledgeSettings}
              hasChanges={hasKnowledgeChanges}
              scenarioId={lesson?.scenario.id}
            />
          </ControlPanel>

          <ControlPanel
            title="Karta pracy"
            icon={Dumbbell}
            isOpen={activePanel === 'worksheet' && isPanelExpanded}
            isActive={activePanel === 'worksheet'}
            onToggle={() => {
              if (activePanel === 'worksheet') {
                if (!isLargeScreen) {
                  setIsPanelExpanded(!isPanelExpanded);
                }
              } else {
                setActivePanel('worksheet');
                if (!isLargeScreen) {
                  setIsPanelExpanded(false);
                }
              }
            }}
            onChevronClick={() => {
              setActivePanel('worksheet');
              if (!isLargeScreen) {
                setIsPanelExpanded(activePanel !== 'worksheet' || !isPanelExpanded);
              }
            }}
            badge={lesson?.worksheet?.items?.length}
          >
            <WorksheetPanelContent
              onGeneratePuzzle={handleGenerateWorksheetItem}
              isGeneratingPuzzle={isGeneratingWorksheetItem !== null}
              showSolutions={showWorksheetSolutions}
              onShowSolutionsChange={setShowWorksheetSolutions}
              puzzles={lesson?.worksheet?.items || []}
              disabled={generatingScenario}
              isEditable={isLessonEditable}
              worksheet={lesson?.worksheet ?? null}
              onUndo={handleWorksheetUndo}
              onRedo={handleWorksheetRedo}
              canUndo={canUndoWorksheet}
              canRedo={canRedoWorksheet}
              language={lesson?.scenario.language}
              lessonId={lessonId}
            />
          </ControlPanel>

      </div>

      {/* Right scrollable column - Main Content */}
      {/* lg:1024px, use column layout */}
      <div className="absolute inset-y-0 left-0 right-0 lg:top-0 lg:left-60 xl:left-0 overflow-y-auto print:static print:overflow-visible">
        <div className="mx-auto print:w-full print:max-w-none">
          <div ref={contentRef} className="p-4 pt-20 lg:p-6 lg:pt-6">
          {activePanel === 'scenario' ? (
            <ScenarioView
              content={scenarioContent}
              onHeaderAction={isLessonEditable ? handleSectionModification : undefined}
              activeModifications={scenarioAlterations}
              isGenerating={generatingScenario}
            />
          ) : activePanel === 'knowledge' ? (
            <KnowledgeView
              content={knowledgeContent || ''}
              isGenerating={generatingKnowledge}
              onHeaderAction={isLessonEditable ? handleKnowledgeModification : undefined}
              activeModifications={knowledgeAlternations}
              onGenerate={isLessonEditable ? () => regenerateKnowledge({
                alterations: knowledgeAlternations
              }) : undefined}
            />
          ) : (
            <WorksheetView
              worksheet={lesson?.worksheet ?? null}
              scenarioDifficulty={lesson?.scenario.prompt.settings.difficulty ?? 0}
              showSolutions={showWorksheetSolutions}
              isGeneratingPuzzle={isGeneratingWorksheetItem}
              onRegeneratePuzzle={isLessonEditable ? handleRegeneratePuzzle : undefined}
              onRemovePuzzle={isLessonEditable ? handleRemoveWorksheetItem : undefined}
              isEditable={isLessonEditable}
              onUndo={handleWorksheetUndo}
              onRedo={handleWorksheetRedo}
              canUndo={canUndoWorksheet}
              canRedo={canRedoWorksheet}
              lessonId={lessonId}
            />
          )}
          </div>
        </div>
      </div>

      {generatingScenario && activePanel === 'scenario' && (
        <GenerationStatus
          label='Generowanie scenariusza...'
          progress={generatingScenarioProgress}
          onAbort={handleAbort}
          autoScroll={autoScroll}
          onAutoScrollChange={applyAutoScroll}
        />
      )}
      {generatingKnowledge && activePanel === 'knowledge' && (
        <GenerationStatus
          label='Generowanie kompendium...'
          progress={generatingKnowledgeProgress}
          onAbort={handleAbort}
          autoScroll={autoScroll}
          onAutoScrollChange={applyAutoScroll}
        />
      )}

      {/* Floating Print Button */}
      <TooltipProvider>
        <div className="fixed bottom-6 right-6 print:hidden">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                onClick={handlePrint}
                disabled={generatingScenario || generatingKnowledge || isGeneratingWorksheetItem !== null}
                className="rounded-full h-12 w-12 shadow-lg hover:shadow-xl transition-shadow [&_svg]:!h-6 [&_svg]:!w-6 disabled:opacity-50"
              >
                <Printer strokeWidth={1} />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="left">
              <p>Drukuj</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </TooltipProvider>

      {/* Print-only footer */}
      <div className="hidden print:block print:relative print:w-full print:text-center print:py-1 print:text-sm print:text-gray-500 print:bg-white print:mt-0">
        www.SuperLekcja.pl - stwórz plan lekcji w minutę!
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />

      <ImageDialog
        isOpen={isImageDialogOpen}
        onClose={() => setIsImageDialogOpen(false)}
        images={generatedImages}
      />
    </div>
  )
} 