import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import './styles/index.css'
import './styles/print.css'
import * as Sentry from "@sentry/react";
import { feedback } from './feedback';
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js'


posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY!, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST!,
})

Sentry.init({
  environment: import.meta.env.MODE ?? 'production',
  dsn: "https://b1b1b198353b3a7167821e7d4442fa5f@o4508935091191808.ingest.de.sentry.io/4508935096893520",
  integrations: [
    Sentry.globalHandlersIntegration({ onerror: true, onunhandledrejection: true }),
    Sentry.httpClientIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ['error']
    }),
    feedback,
  ]
});

// Initialize dev logger in development mode
// setupDevLogger();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
) 