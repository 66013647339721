import { useNavigate } from 'react-router-dom'
import { PasswordChangeForm } from '../components/auth/PasswordChangeForm'

export function InviteAcceptedPage() {
  const navigate = useNavigate()

  const handleSuccess = () => {
    navigate('/', { replace: true })
  }

  return (
    <div className="container mx-auto max-w-md py-12">
      <PasswordChangeForm 
        onSuccess={handleSuccess}
        subtitle="Cieszymy się że zaakceptowałeś zaproszenie. Ustaw hasło do swojego konta."
        title="Witaj w Superlekcji!"
        submitLabel="Zastosuj"
      />
    </div>
  )
} 
