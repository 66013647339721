import posthog from "posthog-js";

// Declare the global plausible function type
declare global {
  function plausible(eventName: string, options?: { props?: Record<string, string | number | boolean> }): void;
}

export enum AnalyticsEvent {
  GENERATE_SCENARIO = 'generate_scenario',
  REGENERATE_SCENARIO = 'regenerate_scenario',
  GENERATE_KNOWLEDGE = 'generate_knowledge',
  REGENERATE_KNOWLEDGE = 'regenerate_knowledge',
  GENERATE_WORKSHEET_ITEM = 'generate_worksheet_item',
  REGENERATE_WORKSHEET_ITEM = 'regenerate_worksheet_item',
  PURCHASE_CREDITS = 'purchase_credits',
}

// Props types for each event
type BaseProps = {
  success: boolean;
  difficulty?: number;
  errorMessage?: string;
}

type ExtraScenarioProps = {
  duration?: number;
  hasAlterations?: boolean;
  alterationsCount?: number;
}

type GenerateScenarioProps = BaseProps & ExtraScenarioProps

type GenerateKnowledgeProps = BaseProps & ExtraScenarioProps

type GenerateWorksheetItemProps = BaseProps & {
  worksheetItemType: string;
}

type PurchaseCreditsProps = {
  amount: number;
  currency: string;
  success: boolean;
}

/**
 * Converts props to Plausible-compatible format by removing undefined values
 */
const sanitizeProps = (props: Record<string, any>): Record<string, string | number | boolean> => {
  return Object.fromEntries(
    Object.entries(props).filter(([_, value]) => value !== undefined)
  ) as Record<string, string | number | boolean>;
};


function sendEvent(event: string, rawprops: Record<string, any>) {
  const props = sanitizeProps(rawprops);
  posthog.capture(event, props);
  plausible(event, { props });
}

/**
 * Tracks scenario generation event
 */
export const trackGenerateScenario = (props: GenerateScenarioProps): void => {
  sendEvent(AnalyticsEvent.GENERATE_SCENARIO, props);
};

/**
 * Tracks scenario regeneration event
 */
export const trackRegenerateScenario = (props: GenerateScenarioProps): void => {
  sendEvent(AnalyticsEvent.REGENERATE_SCENARIO, props);
};

/**
 * Tracks knowledge generation event
 */
export const trackGenerateKnowledge = (props: GenerateKnowledgeProps): void => {
  sendEvent(AnalyticsEvent.GENERATE_KNOWLEDGE, props);
};

/**
 * Tracks knowledge regeneration event
 */
export const trackRegenerateKnowledge = (props: GenerateKnowledgeProps): void => {
  sendEvent(AnalyticsEvent.REGENERATE_KNOWLEDGE, props);
};

/**
 * Tracks worksheet item generation event
 */
export const trackGenerateWorksheetItem = (props: GenerateWorksheetItemProps): void => {
  sendEvent(AnalyticsEvent.GENERATE_WORKSHEET_ITEM, props);
};

/**
 * Tracks worksheet item regeneration event
 */
export const trackRegenerateWorksheetItem = (props: GenerateWorksheetItemProps): void => {
  sendEvent(AnalyticsEvent.REGENERATE_WORKSHEET_ITEM, props);
};

/**
 * Tracks credits purchase event
 */
export const trackPurchaseCredits = (props: PurchaseCreditsProps): void => {
  sendEvent(AnalyticsEvent.PURCHASE_CREDITS, props);
}; 