import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { supabase } from '../lib/supabase';
import { ApiError } from '@superlekcja/shared';

// Create axios instance with default config
export const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : '/api';
export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Authentication helper functions
export async function getAuthToken(): Promise<string | null> {
    // Wait for the session to be initialized
    const { data: { session } } = await supabase.auth.getSession();
    return session?.access_token ?? null;
  }
  

// Add request interceptor to add auth token
api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      try {
        const token = await getAuthToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        } else {
          // anonymous calls are allowed for some endpoints
          return config;
        }
      } catch (error) {
        console.error('Error getting auth token:', error);
        return Promise.reject(error);
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  // Add response interceptor to handle auth errors
  api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        // Just log the unauthorized error without signing out
        console.error('Unauthorized request:', error);
      }
      return Promise.reject(error);
    }
  );
  
  
  
  // Type guard for API error response
  export function isApiError(response: unknown): response is ApiError {
    return (response as ApiError)?.status === 'error';
  }
  export const INSUFFICIENT_CREDITS = 'INSUFFICIENT_CREDITS'
  