import { useState } from 'react'
import { 
  UserPlus,
  Menu,
} from 'lucide-react'
import { UserAvatar } from '../auth/UserAvatar'
import { AuthModal } from '../auth/AuthModal'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'
import { BetaBadge } from '../BetaBadge'
import { ModelSelector } from '../ModelSelector'
import { LogoIcon } from '../icons/LogoIcon'
import { Sheet, SheetContent, SheetTrigger, SheetTitle } from '../ui/sheet'

export function AppBar() {
  const { user, isAuthenticated } = useAuth()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const menuItems = [
    { label: 'start', path: '/', testId: 'home-link' },
    ...(isAuthenticated ? [
      { label: 'moje lekcje', path: '/scenarios', testId: 'scenarios-link' },
    ] : [])
  ]

  return (
    <>
      <div className="no-print fixed top-0 left-0 right-0 h-16 bg-primary border-b border-primary/20 flex items-center px-4 justify-between z-50 text-primary-foreground">
        <div className="flex items-center gap-4">
          {/* Mobile Menu */}
          <Sheet>
            <SheetTrigger className="lg:hidden p-2 hover:bg-primary-foreground/10 rounded-lg text-primary-foreground/80 hover:text-primary-foreground transition-colors">
              <Menu size={20} />
            </SheetTrigger>
            <SheetContent 
              side="left" 
              className="w-[280px] bg-primary text-primary-foreground border-none"
              aria-describedby="mobile-menu-description"
            >
              <SheetTitle className="sr-only">Menu nawigacyjne</SheetTitle>
              <div id="mobile-menu-description" className="sr-only">
                Menu nawigacyjne zawierające dostęp do profilu użytkownika, wybór modelu oraz linki do głównych sekcji aplikacji.
              </div>
              <div className="flex flex-col gap-6">
                <div className="px-3">
                  {isAuthenticated ? (
                    <UserAvatar email={user?.email || ''} />
                  ) : (
                    <button
                      onClick={() => setShowAuthModal(true)}
                      className="flex items-center gap-2 w-full px-3 py-2 hover:bg-primary-foreground/10 rounded-lg text-primary-foreground/80 hover:text-primary-foreground transition-colors"
                      title="Zaloguj się"
                      data-testid="login-button"
                    >
                      <UserPlus size={20} />
                      <span>Zaloguj się</span>
                    </button>
                  )}
                </div>

                <div className="px-3">
                  <ModelSelector />
                </div>

                <div className="flex-1" /> {/* Spacer */}

                <div className="flex flex-col gap-2">
                  {menuItems.map((item) => (
                    <button
                      key={item.path}
                      data-testid={`mobile-${item.testId}`}
                      onClick={() => navigate(item.path)}
                      className={`px-3 py-2 transition-colors text-base font-medium rounded-lg ${
                        location.pathname === item.path
                          ? 'bg-primary-foreground/10 text-primary-foreground'
                          : 'text-primary-foreground/80 hover:bg-primary-foreground/10 hover:text-primary-foreground'
                      }`}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              </div>
            </SheetContent>
          </Sheet>

          <div className="flex items-center gap-2 cursor-pointer" onClick={() => navigate('/')}>
            <LogoIcon className="w-6 h-6 mt-1" />
            <div className="text-3xl font-['Lacquer'] tracking-wide">
              superlekcja.pl
            </div>
            <BetaBadge className="ml-4 mt-1 opacity-30" />
          </div>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex items-center gap-6">
          <div className="flex items-center">
            {menuItems.map((item, index) => (
              <div key={item.path} className="flex items-center">
                <button
                  data-testid={item.testId}
                  onClick={() => navigate(item.path)}
                  className={`px-3 py-1 transition-colors text-base font-medium ${
                    location.pathname === item.path
                      ? 'text-primary-foreground hover:underline'
                      : 'text-primary-foreground/80 hover:text-primary-foreground hover:underline'
                  }`}
                >
                  {item.label}
                </button>
                {index < menuItems.length - 1 && (
                  <span className="text-primary-foreground/40 px-2">|</span>
                )}
              </div>
            ))}
          </div>

          <ModelSelector />
          {isAuthenticated ? (
            <UserAvatar
              email={user?.email || ''}
            />
          ) : (
            <button
              onClick={() => setShowAuthModal(true)}
              className="p-2 hover:bg-primary-foreground/10 rounded-lg text-primary-foreground/80 hover:text-primary-foreground transition-colors"
              title="Zaloguj się"
              data-testid="login-button"
            >
              <UserPlus size={20} />
            </button>
          )}
        </div>
      </div>

      <AuthModal
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
      />
    </>
  )
} 