import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { AuthForm } from './AuthForm'
import { AuthCookieConsent } from './AuthCookieConsent'
import { privacyService } from '../../services/privacy.service'

interface AuthModalProps {
  isOpen: boolean
  onClose: () => void
}

export function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const [showCookieConsent, setShowCookieConsent] = useState(!privacyService.hasCookiesAccepted())

  const handleCookieAccept = () => {
    setShowCookieConsent(false)
  }

  const handleCookieReject = () => {
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onClose}
    >
      <DialogContent 
        className="sm:max-w-[425px]"
        data-testid="auth-modal"
      >
        <DialogHeader>
          <DialogTitle data-testid="auth-modal-header-title">
            {showCookieConsent ? 'Logowanie wymaga cookies' : 'Zaloguj się lub zarejestruj'}
          </DialogTitle>
        </DialogHeader>
        {showCookieConsent ? (
          <AuthCookieConsent 
            onAccept={handleCookieAccept}
            onReject={handleCookieReject}
          />
        ) : (
          <AuthForm onSuccess={onClose} />
        )}
      </DialogContent>
    </Dialog>
  )
} 