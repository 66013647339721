import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SuggestionDTO } from '@superlekcja/shared'
import { Button } from './ui/button'
import { ChevronLeft, ChevronRight, Loader2 } from 'lucide-react'
import { SuggestionTile } from './SuggestionTile'
import { getSuggestions } from '../services/lesson.service'
import { useQuery } from '@tanstack/react-query'

const ITEMS_PER_PAGE = 4;

const fetchSuggestions = async (page: number) => {
  const response = await getSuggestions(page, ITEMS_PER_PAGE);
  return response.suggestions || [];
};

export function SuggestionsPanel() {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(0)
  const [displayedSuggestions, setDisplayedSuggestions] = useState<SuggestionDTO[]>([])

  const { data: suggestions, isLoading } = useQuery<SuggestionDTO[]|undefined>({
    queryKey: ['suggestions', currentPage],
    queryFn: () => fetchSuggestions(currentPage),
    staleTime: 15 * 60 * 1000, // Consider data fresh for 15 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  useEffect(() => {
    if (suggestions) {
      setDisplayedSuggestions(suggestions)
    }
  }, [suggestions])

  const handleNextPage = () => {
    if (suggestions && suggestions.length < ITEMS_PER_PAGE) {
      // If we have less than 4 items, we're on the last page
      // Go back to the first page
      setCurrentPage(0);
    } else {
      setCurrentPage(currentPage + 1);
    }
  }

  const handlePrevPage = () => {
    if (currentPage === 0) {
      // If we're on the first page and going back,
      // we need to find the last page by going forward until we find a page with less than 4 items
      const findLastPage = async () => {
        let page = 0;
        while (true) {
          const response = await getSuggestions(page + 1, ITEMS_PER_PAGE);
          if (!response.suggestions || response.suggestions.length < ITEMS_PER_PAGE) {
            setCurrentPage(page + 1);
            break;
          }
          if (page > 100) { // Safety check to prevent infinite loops
            setCurrentPage(0);
            break;
          }
          page++;
        }
      };
      findLastPage();
    } else {
      setCurrentPage(currentPage - 1);
    }
  }

  const onSuggestionClick = (id: string) => {
    navigate(`/lessons/${id}`)
  }

  // Only show loading state on initial load with no data
  if (isLoading && displayedSuggestions.length === 0) {
    return null;
  }

  if (displayedSuggestions.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader2 className="h-6 w-6 animate-spin text-white" />
          </div>
        )}
        {displayedSuggestions.map((suggestion: SuggestionDTO) => (
          <SuggestionTile
            key={suggestion.id}
            suggestion={suggestion}
            onClick={onSuggestionClick}
          />
        ))}
      </div>
      <div className="flex justify-center gap-2">
        <Button
          variant="ghost"
          size="icon"
          onClick={handlePrevPage}
          disabled={isLoading}
          className="h-8 w-8 text-white hover:text-white hover:bg-white/10"
        >
          <ChevronLeft className="h-4 w-4 text-primary" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleNextPage}
          disabled={isLoading}
          className="h-8 w-8 text-white hover:text-white hover:bg-white/10"
        >
          <ChevronRight className="h-4 w-4 text-primary" />
        </Button>
      </div>
    </div>
  )
} 