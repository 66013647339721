import { useEffect, useRef } from 'react'
import { CrosswordData, QuizData, RiddleData, WordSearchData, WorksheetDTO, WorksheetItemDTO, WorksheetItemType, ProblemSolvingData, MemoryData, MillionairesData } from "@superlekcja/shared";
import { CrosswordPuzzle } from "./puzzles/Crossword";
import { Quiz } from "./puzzles/Quiz";
import { WordSearch } from "./puzzles/WordSearch";
import { Riddle } from "./puzzles/Riddles";
import { ProblemSolving } from "./puzzles/ProblemSolving";
import { Memory } from "./puzzles/Memory";
import { MillionairesEditor } from "./puzzles/MillionairesEditor";
import { Panel } from './Panel';
import { Header } from './Header';
import { Button } from "./ui/button";
import { Loader2, Pencil, RefreshCw, Trash2, AlertTriangle, ArrowLeft } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

interface WorksheetProps {
  worksheet: WorksheetDTO|null;
  scenarioDifficulty: number;
  showSolutions: boolean;
  onRegeneratePuzzle?: (puzzleId: string, type: WorksheetItemType) => void;
  onRemovePuzzle?: (puzzleId: string) => void;
  isGeneratingPuzzle?: { type: WorksheetItemType; id?: string } | null;
  lessonId?: string;
  isEditable?: boolean;
  onUndo?: () => void;
  onRedo?: () => void;
  canUndo?: boolean;
  canRedo?: boolean;
}

export function WorksheetView({ 
  worksheet, 
  scenarioDifficulty,
  showSolutions,
  onRegeneratePuzzle,
  onRemovePuzzle,
  isGeneratingPuzzle,
  lessonId,
  isEditable = true,
  onUndo,
  onRedo,
  canUndo = false,
  canRedo = false
}: WorksheetProps) {
  const bottomRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom when generating a new puzzle (not regenerating)
  useEffect(() => {
    if (isGeneratingPuzzle && !isGeneratingPuzzle.id) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isGeneratingPuzzle]);

  function renderPuzzle(puzzle: WorksheetItemDTO) {
    // If this puzzle is being regenerated, show loading state
    if (isGeneratingPuzzle?.id === puzzle.id) {
      return (
        <div data-testid={`${puzzle.type}-puzzle-generating`} className="flex flex-col items-center justify-center py-12 space-y-4 bg-gray-50/50 rounded-lg border border-gray-100">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <p className="text-sm text-muted-foreground">
            Generuję {getPuzzleTitle(puzzle.type).toLowerCase()}...
          </p>
        </div>
      );
    }

    const puzzleData = puzzle.data;
    switch (puzzle.type) {
      case 'crossword':
        return <CrosswordPuzzle puzzle={puzzleData as CrosswordData} showPreview={showSolutions} />
      case 'quiz':
        return <Quiz data={puzzleData as QuizData} showAnswers={showSolutions} />
      case 'wordsearch':
        return <WordSearch data={puzzleData as WordSearchData} showSolution={showSolutions} />
      case 'riddle':
        return <Riddle data={puzzleData as RiddleData} showAnswers={showSolutions} showHints={showSolutions} />
      case 'problem-solving':
        return <ProblemSolving data={puzzleData as ProblemSolvingData} showSolution={showSolutions} />
      case 'memory':
        return <Memory data={puzzleData as MemoryData} showSolution={showSolutions} />
      case 'millionaires':
        return lessonId ? (
          <MillionairesEditor 
            data={puzzleData as MillionairesData} 
            showAnswers={showSolutions} 
            lessonId={lessonId}
            itemId={puzzle.id}
          />
        ) : null;
      default:
        return null;
    }
  }

  const getPuzzleTitle = (type: string): string => {
    switch (type) {
      case 'crossword':
        return 'Krzyżówka';
      case 'quiz':
        return 'Quiz';
      case 'wordsearch':
        return 'Wykreślanka';
      case 'riddle':
        return 'Zagadki';
      case 'problem-solving':
        return 'Rozwiąż problem';
      case 'memory':
        return 'Technika pamięciowa';
      case 'millionaires':
        return 'Kujonerzy';
      default:
        return type;
    }
  };

  const renderPuzzleMenu = (puzzle: WorksheetItemDTO) => {
    if (!onRegeneratePuzzle && !onRemovePuzzle) return null;

    const isRegenerating = isGeneratingPuzzle?.id === puzzle.id;
    const hasDifficultyMismatch = puzzle.difficulty !== scenarioDifficulty;

    return (
      <div className="flex items-center gap-2">
        {hasDifficultyMismatch && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <AlertTriangle className="h-4 w-4 text-amber-500" />
              </TooltipTrigger>
              <TooltipContent>
                <p>Poziom trudności zadania ({puzzle.difficulty}) różni się od poziomu trudności scenariusza ({scenarioDifficulty}). Rozważ wygenerowanie zadania ponownie.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="ghost" 
              size="icon" 
              className="h-8 w-8"
              disabled={isRegenerating}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {onRegeneratePuzzle && (
              <DropdownMenuItem 
                onClick={() => onRegeneratePuzzle(puzzle.id, puzzle.type)}
                disabled={isRegenerating}
              >
                <RefreshCw className={`h-4 w-4 mr-2 ${isRegenerating ? 'animate-spin' : ''}`} />
                Wygeneruj ponownie
              </DropdownMenuItem>
            )}
            {onRemovePuzzle && (
              <DropdownMenuItem 
                onClick={() => onRemovePuzzle(puzzle.id)}
                className="text-red-600 focus:text-red-600"
                disabled={isRegenerating}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Usuń
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  return (
    <Panel>
            <Header level={1} title="Karta pracy" className="mb-6" />
            {!worksheet?.items?.length && !isGeneratingPuzzle && (
              <div className="flex flex-col items-center justify-center py-16 text-center space-y-4">
                <div className="text-muted-foreground">
                  <p className="mb-2">Twoja karta pracy jest pusta.</p>
                  <p className="flex items-center gap-2">
                    <span>Dodaj ćwiczenia korzystając z panelu "Karta pracy".</span>
                  </p>
                </div>
              </div>
            )}
   
            {worksheet?.items?.map((puzzle, index) => (
              <div key={puzzle.id} className="mb-8 print:break-inside-avoid-page">
                <Header 
                  level={2} 
                  title={getPuzzleTitle(puzzle.type)} 
                  className="mb-4"
                  actionSlot={renderPuzzleMenu(puzzle)}
                />
                {renderPuzzle(puzzle)}
              </div>
            ))}

          {/* Loading state for new puzzle being generated */}
          {isGeneratingPuzzle && !isGeneratingPuzzle.id && (
            <div className="mb-8" ref={bottomRef}>
              <Header 
                level={2} 
                title={getPuzzleTitle(isGeneratingPuzzle.type)} 
                className="mb-4"
              />
              <div className="flex flex-col items-center justify-center py-12 space-y-4 bg-gray-50/50 rounded-lg border border-gray-100 printable-content">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
                <p className="text-sm text-muted-foreground">
                  Generuję {getPuzzleTitle(isGeneratingPuzzle.type).toLowerCase()}...
                </p>
              </div>
            </div>
          )}
    </Panel>
  );
} 