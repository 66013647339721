import { useState, useEffect } from 'react'
import { X, PlayCircle } from 'lucide-react'
import { Dialog, DialogContent } from './ui/dialog'
import { Button } from './ui/button'

export function VideoDemo() {
  const [isOpen, setIsOpen] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)

  useEffect(() => {
    const dismissed = localStorage.getItem('videoDemoDismissed')
    if (!dismissed) {
      setShouldShow(true)
    }
  }, [])

  const handleDismiss = (e: React.MouseEvent) => {
    e.stopPropagation()
    setShouldShow(false)
    localStorage.setItem('videoDemoDismissed', 'true')
  }

  if (!shouldShow) {
    return null
  }

  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        className="fixed right-10 bottom-10 inline-block cursor-pointer group"
      >
        <div className="relative">
          <img 
            src="/dymek.png" 
            alt="o co chodzi?"
            className="h-20 w-auto hover:opacity-90 transition-opacity"
          />
          <button
            onClick={handleDismiss}
            className="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 transition-colors"
          >
            <X className="h-3 w-3" />
          </button>
        </div>
      </div>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="sm:max-w-[800px] p-0">
          <div className="aspect-video bg-black rounded-lg overflow-hidden">
            <video 
              className="w-full h-full"
              controls
              autoPlay
              playsInline
            >
              <source 
                src="https://tffhaktwcgdyvobrrnvw.supabase.co/storage/v1/object/public/www/videos/intro_1080.mp4"
                type="video/mp4"
              />
              <source 
                src="https://tffhaktwcgdyvobrrnvw.supabase.co/storage/v1/object/public/www/videos/intro_720.mp4"
                type="video/mp4"
              />
              Twoja przeglądarka nie wspiera odtwarzania wideo.
            </video>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
} 