import { type ComponentProps } from 'react'

interface LogoIconProps extends ComponentProps<'svg'> {
  className?: string
}

export function LogoIcon({ className, ...props }: LogoIconProps) {
  return (
    <svg 
      className={className}
      viewBox="0 0 70 70" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >

      <g>
        <path 
          className="fill-white" 
          d="M35.208,3.833l-0.415,0c-11.339,0 -20.53,8.794 -20.53,19.643l0,0.397c0,5.661 2.505,10.76 6.51,14.344c1.827,1.634 2.844,3.931 2.844,6.323l-0,0.085c-1.076,0.075 0.064,15.173 0.064,15.173c0.346,2.218 2.364,3.265 4.699,3.766c1.326,1.555 3.79,2.603 6.62,2.603c2.93,-0 5.473,-1.122 6.76,-2.769c0.101,-0.02 0.203,-0.036 0.305,-0.058c2.02,-0.446 3.904,-1.305 4.253,-3.543c0.001,-0.006 1.142,-15.133 0.065,-15.207l0,-0.048c0,-2.392 1.017,-4.69 2.844,-6.323c4.005,-3.584 6.51,-8.683 6.51,-14.344l-0,-0.399c0.001,-10.849 -9.191,-19.643 -20.529,-19.643Zm7.968,56.991c-2.874,0.748 -5.756,1.122 -8.621,1.122c-2.709,0 -5.404,-0.335 -8.069,-1.003c-0.397,-0.099 -0.637,-1.581 -0.536,-1.973c0.101,-0.392 0.503,-0.628 0.901,-0.53c5.236,1.315 10.601,1.276 15.947,-0.116c0.393,-0.104 0.8,0.13 0.905,0.522c0.104,0.391 -0.132,1.875 -0.527,1.978Zm0.609,-5.291c-3.078,0.858 -6.163,1.286 -9.231,1.286c-2.904,0 -5.793,-0.384 -8.647,-1.152c-0.395,-0.106 -0.628,-1.592 -0.52,-1.982c0.107,-0.391 0.516,-0.621 0.91,-0.514c5.607,1.509 11.356,1.465 17.086,-0.13c0.392,-0.11 0.803,0.117 0.914,0.505c0.112,0.39 -0.118,1.877 -0.512,1.987Zm0.423,-5.247c-3.22,0.937 -6.447,1.407 -9.654,1.407c-3.031,-0 -6.044,-0.419 -9.022,-1.256c-0.393,-0.11 -0.622,-1.599 -0.509,-1.988c0.111,-0.388 0.52,-0.614 0.915,-0.503c5.854,1.646 11.86,1.597 17.851,-0.146c0.392,-0.115 0.805,0.106 0.92,0.494c0.116,0.388 -0.108,1.879 -0.501,1.992Zm7.387,-26.413c-0,4.935 -2.082,9.515 -5.862,12.897c-2.188,1.958 -2.374,4.738 -2.409,7.646c-1.587,0.491 -2.092,0.862 -3.672,1.109l-2.062,0.25c-0.894,0.08 -1.785,0.125 -2.669,0.125c-0.835,-0 -1.672,-0.039 -2.509,-0.11l-2.059,-0.246c-1.592,-0.246 -2.098,-0.618 -3.676,-1.118c-0.033,-2.911 -0.218,-5.696 -2.409,-7.657c-3.78,-3.381 -5.861,-7.962 -5.861,-12.897l-0,-0.396c-0,-5.79 2.169,-10.177 6.073,-12.786c2.672,-1.786 6.156,-2.738 10.314,-2.738l0.414,-0c5.009,-0 9.042,1.383 11.853,3.94c2.931,2.665 4.534,6.606 4.534,11.584l0,0.397l-0,-0Z"
        />
      </g>
    </svg>
  )
} 