import React from 'react';
import { Button } from '../components/ui/button';
import { 
  trackGenerateScenario,
  trackRegenerateScenario,
  trackGenerateKnowledge,
  trackGenerateWorksheetItem,
  trackPurchaseCredits
} from '../utils/analytics';
import * as Sentry from "@sentry/react";
import { toast } from '../components/ui/use-toast';

export function TestPage() {
  const showToast = (eventName: string, props?: Record<string, any>) => {
    toast({
      title: "Analytics Event Triggered",
      description: `Event: ${eventName}${props ? ` with props: ${JSON.stringify(props)}` : ''}`,
    });
  };

  const triggerSentryError = (type: string) => {
    try {
      switch (type) {
        case 'error':
          throw new Error('Test error for Sentry monitoring');
        case 'promise':
          Promise.reject(new Error('Test promise rejection for Sentry'));
          break;
        case 'console':
          console.error('Test console error for Sentry');
          break;
        default:
          throw new Error(`Unknown error type: ${type}`);
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Sentry Error Triggered",
        description: `Type: ${type}`,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto py-12 px-4">
      <h1 className="text-3xl font-bold mb-8">Test Page</h1>
      
      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold mb-4">Analytics Events</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Button 
              onClick={() => {
                const props = { difficulty: 2, duration: 45 };
                trackGenerateScenario({ success: true, ...props });
                showToast('generate_scenario', props);
              }}
            >
              Trigger Generate Scenario Event
            </Button>
            <Button 
              onClick={() => {
                const props = { 
                  difficulty: 3, 
                  duration: 30, 
                  hasAlterations: true,
                  alterationsCount: 1
                };
                trackRegenerateScenario({ success: true, ...props });
                showToast('regenerate_scenario', props);
              }}
            >
              Trigger Regenerate Scenario Event
            </Button>
            <Button 
              onClick={() => {
                const props = { difficulty: 2 };
                trackGenerateKnowledge({ success: true, ...props });
                showToast('generate_knowledge', props);
              }}
            >
              Trigger Generate Knowledge Event
            </Button>
            <Button 
              onClick={() => {
                const props = { worksheetItemType: 'quiz', difficulty: 2 };
                trackGenerateWorksheetItem({ success: true, ...props });
                showToast('generate_worksheet_item', props);
              }}
            >
              Trigger Generate Worksheet Item Event
            </Button>
            <Button 
              onClick={() => {
                const props = { amount: 100, currency: "PLN" };
                trackPurchaseCredits({ success: true, ...props });
                showToast('purchase_credits', props);
              }}
            >
              Trigger Purchase Credits Event
            </Button>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Sentry Errors</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Button 
              variant="destructive"
              onClick={() => triggerSentryError('error')}
            >
              Trigger Error Exception
            </Button>
            <Button 
              variant="destructive"
              onClick={() => triggerSentryError('promise')}
            >
              Trigger Promise Rejection
            </Button>
            <Button 
              variant="destructive"
              onClick={() => triggerSentryError('console')}
            >
              Trigger Console Error
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
} 