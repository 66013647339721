import { MemoryData } from "@superlekcja/shared";
import { MarkdownView } from "../MarkdownView";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { SimpleMarkdownView } from "../SimpleMarkdownView";

interface MemoryProps {
  data: MemoryData;
  showSolution: boolean;
}

export function Memory({ data, showSolution }: MemoryProps) {
  return (
      <SimpleMarkdownView content={data.content} />
  );
} 