import { ApiSuccess, ProfileDTO } from '@superlekcja/shared';
import { api } from './api';


export interface Transaction {
  id: string;
  user_id: string;
  credits_amount: number;
  money_amount: number;
  created_at: string;
  status: 'completed' | 'pending' | 'failed';
  payment_method: string;
  payment_reference?: string;
}

type PaymentType = 'blik' | 'other';

interface PaymentBase {
  type: PaymentType;
}

interface BlikPayment extends PaymentBase {
  type: 'blik';
  code: string;
}

interface OtherPayment extends PaymentBase {
  type: 'other';
}

type Payment = BlikPayment | OtherPayment;

interface PaymentResponse {
  redirectUrl?: string;
  orderId?: string;
  extOrderId: string;
}

class ProfileService {
 
  async fetchProfile(): Promise<ProfileDTO> {
    console.log('Fetching profile...');
    const response = await api.get<ApiSuccess<ProfileDTO>>('/profile');
    const profile = response.data.data;
    console.log('Fetched profile:', profile);
    return profile;
  }

  async purchaseCredits(
    amount: number, 
    paymentType: PaymentType,
    blikCode?: string
  ): Promise<PaymentResponse> {
    const payment: Payment = paymentType === 'blik' 
      ? { type: 'blik', code: blikCode! }
      : { type: 'other' };

    const response = await api.post<ApiSuccess<PaymentResponse>>('/payments/credits/purchase', {
      amount,
      returnPath: window.location.pathname,
      payment
    });
    return response.data.data;
  }

  async getTransaction(id: string): Promise<Transaction> {
    const response = await api.get<ApiSuccess<Transaction>>(`/payments/transaction/${id}`);
    return response.data.data;
  }

  async getTransactionByExtId(id: string): Promise<Transaction> {
    const response = await api.get<ApiSuccess<Transaction>>(`/payments/transaction-by-ext-id/${id}`);
    return response.data.data;
  }
}

export const profileService = new ProfileService(); 
