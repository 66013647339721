import React from 'react';
import { SuggestionDTO } from '@superlekcja/shared';
import { getSubjectIcon } from '@/utils/subjectUtils';
import { Trophy } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface SuggestionTileProps {
  suggestion: SuggestionDTO;
  onClick: (id: string) => void;
}

export function SuggestionTile({ suggestion, onClick }: SuggestionTileProps) {
  return (
    <div
      onClick={() => onClick(suggestion.slug)}
      className="bg-transparent p-4 rounded-lg cursor-pointer hover:bg-white/10 hover:scale-[1.02] transition-all duration-200 h-24 relative"
    >
      <div className="flex items-start gap-3 h-full">
        {suggestion.subject && (
          <div className="text-white/80 bg-primary w-10 h-10 rounded-full flex items-center justify-center shrink-0">
            {React.createElement(getSubjectIcon(suggestion.subject), { size: 20 })}
          </div>
        )}
        <div className="flex-1 flex flex-col h-full">
          <div className="font-medium text-primary">
            {suggestion.title}
          </div>
          {suggestion.millionairesId && (
            <Link 
              to={`/lessons/${suggestion.slug}/worksheet/${suggestion.millionairesId}`}
              onClick={(e) => e.stopPropagation()}
              className={cn(
                "inline-flex items-center gap-1 text-xs",
                "bg-transparent text-primary border border-primary",
                "hover:bg-primary hover:text-primary-foreground transition-colors",
                "absolute bottom-4 right-4",
                "py-1 px-2 rounded-md"
              )}
            >
              <Trophy className="w-3 h-3" />
              <span>Graj w Kujonerzy</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
} 