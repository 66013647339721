import { ScenarioGenerationSettingsDTO } from "@superlekcja/shared"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { cn } from "@/lib/utils"

interface GenerationSettingsProps {
  settings: ScenarioGenerationSettingsDTO
  onSettingsChange: (settings: ScenarioGenerationSettingsDTO) => void
  disabled?: boolean
  layout?: 'horizontal' | 'vertical'
  className?: string
}

const DURATION_OPTIONS = [15, 30, 45, 60, 90, 120]
export const DIFFICULTY_OPTIONS = [
  { value: 0, label: 'Przedszkolny' },
  { value: 1, label: 'Łatwy' },
  { value: 2, label: 'Standardowy' },
  { value: 3, label: 'Zaawansowany' }
]

export function ScenarioGenerationSettingsForm({ 
  settings, 
  onSettingsChange, 
  disabled = false,
  layout = 'horizontal',
  className
}: GenerationSettingsProps) {
  return (
    <div 
      className={cn(
        `flex ${layout === 'horizontal' ? 'flex-row flex-wrap' : 'flex-col'} items-start gap-4 text-sm`,
        className
      )}
      data-testid="settings-button"
    >
      <div className={cn(
        "flex-1 min-w-[100px]",
        layout === 'vertical' && "w-full"
      )}>
        <div className="text-xs text-primary-foreground/80 mb-1.5">Czas lekcji</div>
        <Select
          value={settings.duration?.toString() ?? ''}
          onValueChange={(value: string) => onSettingsChange({ ...settings, duration: parseInt(value) })}
          disabled={disabled}
        >
          <SelectTrigger
            className="h-10 w-full bg-transparent border-primary-foreground/20 text-primary-foreground hover:bg-primary-foreground/10"
            data-testid="duration-select"
          >
            <SelectValue placeholder="Wybierz czas" />
          </SelectTrigger>
          <SelectContent>
            {DURATION_OPTIONS.map((value) => (
              <SelectItem
                key={value}
                value={value.toString()}
                data-testid={`duration-option-${value}`}
              >
                {value} minut
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className={cn(
        "flex-1 min-w-[100px]",
        layout === 'vertical' && "w-full"
      )}>
        <div className="text-xs text-primary-foreground/80 mb-1.5">Poziom trudności</div>
        <Select
          value={settings.difficulty?.toString() ?? ''}
          onValueChange={(value: string) => onSettingsChange({ ...settings, difficulty: parseInt(value) })}
          disabled={disabled}
        >
          <SelectTrigger className="h-10 w-full bg-transparent border-primary-foreground/20 text-primary-foreground hover:bg-primary-foreground/10">
            <SelectValue placeholder="Poziom trudności" />
          </SelectTrigger>
          <SelectContent>
            {DIFFICULTY_OPTIONS.map(({ value, label }) => (
              <SelectItem key={value} value={value.toString()} data-testid={`difficulty-option-${value}`}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
} 
